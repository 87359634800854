import { useElementFilter } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import _ from 'lodash';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    value: {
      fontWeight: 'bold',
      overflow: 'hidden',
      lineHeight: '24px',
      wordBreak: 'break-word',
    },
    label: {
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 'bold',
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };
});

/**
 * Props for {@link AboutFieldTMatic}.
 *
 * @public
 */
export interface AboutFieldTMaticProps {
  label: string;
  value?: string | React.ReactNode | JSX.Element;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
}

/** @public */
export function AboutFieldTMatic(props: AboutFieldTMaticProps) {
  const { label, value, gridSizes, children } = props;
  const classes = useStyles();
  const childElements = useElementFilter(children, c => c.getElements());

  let content: React.ReactNode | JSX.Element;
  switch (true) {
    case _.isObject(value): {
      content = value;
      break;
    }
    case childElements.length > 0: {
      content = childElements;
      break;
    }
    default: {
      content = (
        <Typography variant="body2" className={classes.value}>
          {value || 'unknown'}
        </Typography>
      );
    }
  }

  return (
    <Grid item {...gridSizes}>
      <Typography variant="h2" className={classes.label}>
        {label}
      </Typography>
      {content}
    </Grid>
  );
}
