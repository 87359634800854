import { JSONSchema7 as JSONSchema, JSONSchema7TypeName } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';

export const jsonObj = (type?: JSONSchema7TypeName | JSONSchema7TypeName[]): JSONSchema['properties'] => ({
  // dynamic declaration for { [key in string]?: JsonObject | JsonArray | JsonPrimitive }
  S_: { type: type || ['string', 'number', 'boolean', 'null', 'array', 'object'] },
});

export const BaseEntitySchema: JSONSchema = {
  type: 'object',
  properties: {
    apiVersion: { enum: [C.TRANSFORMATIC_IO_V1ALPHA1, C.BACKSTAGE_IO_V1ALPHA1] },
    kind: {
      enum: [C.KIND_API, C.KIND_ARTIFACT, C.KIND_APPLICATION, C.KIND_COMPONENT, C.KIND_GROUP, C.KIND_LOCATION, C.KIND_RESOURCE, C.KIND_SYSTEM, C.KIND_TEMPLATE, C.KIND_USER],
    },
    metadata: {
      type: 'object',
      properties: {
        name: { type: 'string' },
        uid: { type: 'string' },
        etag: { type: 'string' },
        namespace: { type: 'string' },
        title: { type: 'string' },
        description: { type: 'string' },
        labels: { type: 'object', properties: jsonObj('string') },
        annotations: {
          type: 'object',
          properties: {
            ...jsonObj('string'),
            'backstage.io/managed-by-location': { type: 'string', minLength: 1 },
            'backstage.io/managed-by-origin-location': { type: 'string', minLength: 1 },
            'backstage.io/view-url': { type: 'string' },
          },
        },
        tags: { type: 'array', items: { type: 'string' } },
        links: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              url: { type: 'string' },
              title: { type: 'string' },
              icon: { type: 'string' },
              type: { type: 'string' },
            },
            required: ['url'],
          },
        },
        ...jsonObj(),
      },
      required: ['name', 'annotations'],
    },
    spec: {
      type: 'object',
      properties: {
        ...jsonObj(),
        type: { type: 'string' },
        system: { type: 'string', minLength: 1 },
        lifecycle: { type: 'string', minLength: 1 },
        owner: { type: 'string', minLength: 1 },
        partOf: { type: 'string', minLength: 1 },
      },
      required: ['type'],
    },
    // relations: {
    //   type: 'array',
    //   items: {
    //     type: 'object',
    //     properties: {
    //       type: { type: 'string' },
    //       targetRef: { type: 'string' },
    //     },
    //   },
    // },
  },
  required: ['apiVersion', 'kind', 'metadata', 'spec'],
};

export const getBaseEntitySchemaCopy = (omit: string[] = []) => _.clone(_.omit(BaseEntitySchema, omit));
