import { ObjectFieldTemplateProps } from '@rjsf/utils';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import React from 'react';
import _ from 'lodash';
import { useStyles } from './common';

export function ObjectFieldAccordionTemplate(props: ObjectFieldTemplateProps) {
  const classes = useStyles();

  return (
    <div className={classes.objectRoot}>
      <Accordion disabled={props.disabled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${props.idSchema.$is}-content`} id={`${props.idSchema.$is}-header`}>
          <Typography>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {_.map(props.properties, (element, key) => (
            <div className={classes.objectItem} key={key}>
              {element.content}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
