import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const entityComponentsReactPlugin = createPlugin({
  id: 'entity-components-react',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityClusterResourcesCard = entityComponentsReactPlugin.provide(
  createComponentExtension({
    name: 'EntityClusterResourcesCard',
    component: {
      lazy: () => import('./components/ClusterResourcesCard').then(m => m.ClusterResourcesCard),
    },
  }),
);

export const EntityClusterEventsCard = entityComponentsReactPlugin.provide(
  createComponentExtension({
    name: 'EntityClusterEventsCard',
    component: {
      lazy: () => import('./components/ClusterEventsCard').then(m => m.ClusterEventsCard),
    },
  }),
);
