import { PlaceholderSettings } from '../types';
import { ARGOCD, ARGOCD_CONFIG_PLACEHOLDER, readonly as argoCDReadonly } from './argocd';
import { ARGOCD_PROXY, ARGOCD_PROXY_CONFIG_PLACEHOLDER } from './argocd-proxy';
import { BITBUCKET, BITBUCKET_CONFIG_PLACEHOLDER } from './bitbucket';
import { DATADOG, DATADOG_CONFIG_PLACEHOLDER } from './datadog';
import { GITHUB, GITHUB_CONFIG_PLACEHOLDER } from './github';
import { GITLAB, GITLAB_CONFIG_PLACEHOLDER } from './gitlab';
import { GRAFANA, GRAFANA_CONFIG_PLACEHOLDER } from './grafana';
import { JENKINS, JENKINS_CONFIG_PLACEHOLDER } from './jenkins';
import { KUBERNETES, KUBERNETES_CONFIG_PLACEHOLDER } from './kubernetes';
import { NEWRELIC, NEWRELIC_CONFIG_PLACEHOLDER, NEWRELIC_DASHBOARD_CONFIG_PLACEHOLDER } from './newrelic';
import { NPM, NPM_CONFIG_PLACEHOLDER } from './npm';
import { OAUTH, OAUTH_CONFIG_PLACEHOLDER } from './oauth';
import { PAGERDUTY, PAGERDUTY_CONFIG_PLACEHOLDER } from './pagerduty';
import { SENTRY, SENTRY_CONFIG_PLACEHOLDER } from './sentry';

export { ARGOCD_ACCESS_MODE } from './argocd';

export * from './types';
export type { ArgoCDConfigRecord, ArgoCDAccessMode } from './argocd';
export type { GitHubIntegration, HiddenGitHubApp, GitHubApp } from './github';

export type IntegrationSchemaSettingsName =
  | 'ARGOCD'
  | 'ARGOCD_PROXY'
  | 'BITBUCKET'
  | 'DATADOG'
  | 'GITHUB'
  | 'GITLAB'
  // 'GITHUB_APPS' |
  | 'GRAFANA'
  | 'JENKINS'
  | 'KUBERNETES'
  | 'NEWRELIC'
  | 'NEWRELIC_DASHBOARD'
  | 'NPM'
  | 'OAUTH'
  | 'PAGERDUTY'
  | 'SENTRY';

export const INTEGRATIONS_SCHEMAS: Record<IntegrationSchemaSettingsName, PlaceholderSettings> = {
  ARGOCD: {
    schema: ARGOCD,
    configPlaceholder: ARGOCD_CONFIG_PLACEHOLDER,
    allowsMultipleRecords: true,
    preventEditing: argoCDReadonly,
    preventDeletion: argoCDReadonly,
  },
  ARGOCD_PROXY: {
    schema: ARGOCD_PROXY,
    configPlaceholder: ARGOCD_PROXY_CONFIG_PLACEHOLDER,
  },
  BITBUCKET: {
    schema: BITBUCKET,
    configPlaceholder: BITBUCKET_CONFIG_PLACEHOLDER,
  },
  DATADOG: {
    schema: DATADOG,
    configPlaceholder: DATADOG_CONFIG_PLACEHOLDER,
  },
  GITHUB: {
    schema: GITHUB,
    configPlaceholder: GITHUB_CONFIG_PLACEHOLDER,
    allowsMultipleRecords: true,
  },
  // GITHUB_APPS: {
  //   schema: GITHUB_APPS,
  //   configPlaceholder: GITHUB_APPS_CONFIG_PLACEHOLDER,
  // },
  GITLAB: {
    schema: GITLAB,
    configPlaceholder: GITLAB_CONFIG_PLACEHOLDER,
  },
  GRAFANA: {
    schema: GRAFANA,
    configPlaceholder: GRAFANA_CONFIG_PLACEHOLDER,
  },
  JENKINS: {
    schema: JENKINS,
    configPlaceholder: JENKINS_CONFIG_PLACEHOLDER,
  },
  KUBERNETES: {
    schema: KUBERNETES,
    configPlaceholder: KUBERNETES_CONFIG_PLACEHOLDER,
    allowsMultipleRecords: true,
  },
  NEWRELIC: {
    schema: NEWRELIC,
    configPlaceholder: NEWRELIC_CONFIG_PLACEHOLDER,
  },
  NEWRELIC_DASHBOARD: {
    schema: NEWRELIC,
    configPlaceholder: NEWRELIC_DASHBOARD_CONFIG_PLACEHOLDER,
  },
  NPM: {
    schema: NPM,
    configPlaceholder: NPM_CONFIG_PLACEHOLDER,
  },
  OAUTH: {
    schema: OAUTH,
    configPlaceholder: OAUTH_CONFIG_PLACEHOLDER,
    envDependent: true,
  },
  PAGERDUTY: {
    schema: PAGERDUTY,
    configPlaceholder: PAGERDUTY_CONFIG_PLACEHOLDER,
  },
  SENTRY: {
    schema: SENTRY,
    configPlaceholder: SENTRY_CONFIG_PLACEHOLDER,
  },
};
