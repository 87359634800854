import axios from 'axios';
import { useEntity } from '@backstage/plugin-catalog-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeSnippet, InfoCard, Progress, WarningPanel } from '@backstage/core-components';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { BackstageTheme } from '@backstage/theme';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClickToCopy from '../NpmPublicPackageInfoCard/ClickToCopy';
import { ImagesRecord } from '@tmatic/cloud-providers-backend';
import moment from 'moment';
import { choreSize } from '@tmatic/utils';

interface ComponentState {
  loading: boolean;
  value: any | null;
  error: Error | null;
}

interface FetchRepositoryInfoOptions {
  url: string;
  controller: AbortController;
}

const useStyles = makeStyles(() => ({
  subheader: {
    fontWeight: 'bold',
  },
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: useTheme<BackstageTheme>().palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  gridItemCardContent: { flex: 1 },
}));

type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface PackageContentRecord {
  label: string;
  value: string | number;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  contentStyles?: {
    label?: any;
    value?: any;
  };
}

const fetchRepositoryInfo = ({ url, controller }: FetchRepositoryInfoOptions) => {
  return axios
    .get(url, { signal: controller.signal })
    .then(({ data }) => ({
      value: data,
      loading: false,
    }))
    .catch(() => ({
      loading: false,
      error: new Error('Error fetching Repository Info or Package Not Found'),
      value: null,
    }));
};

export const DockerRepositoryInfoLatestCard = () => {
  const classes = useStyles();
  const theme = useTheme<BackstageTheme>();
  const variant = 'gridItem';
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const backendUrl = config.get('backend.baseUrl');
  const cardTitle = 'Images';
  const tagForFilter = 'latest';

  const [state, setState] = useState<ComponentState>({
    loading: false,
    value: null,
    error: null,
  });
  const { value: images, loading, error } = state;

  const controller = useMemo(() => new AbortController(), []);
  const metadata = useMemo(() => entity.metadata, [entity]);

  const fetchMetadata = useCallback(() => {
    const mergeState = (props: Partial<ComponentState>) => setState({ ...state, ...props });

    mergeState({ loading: true });
    if (metadata.name) {
      const { name, namespace = 'default' } = metadata;
      const url = `${backendUrl}/api/cloud-providers/artifacts/${namespace}/${name}/repository-images`;
      fetchRepositoryInfo({ url, controller }).then(mergeState);
    }
  }, [state, setState, controller, backendUrl, metadata]);

  useEffect(() => {
    fetchMetadata();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller]);

  const refreshEntity = useCallback(fetchMetadata, [fetchMetadata]);

  if (loading) {
    return (
      <InfoCard variant={variant} title={cardTitle}>
        <Progress />
      </InfoCard>
    );
  }

  if (error || !_.size(images)) {
    let title: string;
    let severity: 'error' | 'info';
    let message: React.ReactNode;

    if (!_.size(images)) {
      title = `There are no ${cardTitle}`;
      severity = 'info';
    } else {
      title = `Could not load ${cardTitle}`;
      severity = 'error';
      message = <CodeSnippet text={`${error}`} language="text" />;
    }

    return (
      <InfoCard variant={variant} title={cardTitle}>
        <WarningPanel severity={severity} title={title} message={message} />
      </InfoCard>
    );
  }

  const [latestImage] = _.filter(images as ImagesRecord[], image => {
    return _.includes(image.imageTags, tagForFilter);
  });

  const content: PackageContentRecord[] = [
    { label: 'Image tag', value: tagForFilter, lg: 6 },
    { label: 'Created', value: moment(latestImage.imagePushedAt).format('YYYY-MM-DD HH:mm:ss'), lg: 6 },
    { label: 'Image Size', value: choreSize(latestImage.imageSizeInBytes), lg: 6 },
    { label: 'Digest', value: latestImage.imageDigest, lg: 6 },
  ];

  const styles: React.CSSProperties = {};
  if (theme.palette.type === 'dark') {
    styles.backgroundColor = '#28293c';
  }

  return (
    <Card>
      <CardHeader
        title={cardTitle}
        style={{ paddingBottom: '15px', ...styles }}
        action={
          <IconButton aria-label="Refresh" title="Schedule entity refresh" onClick={refreshEntity} size="large">
            <CachedIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent className={classes.gridItemCardContent} style={{ ...styles }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.label}>
              Use
            </Typography>
            <ClickToCopy textToCopy={`docker pull ${entity.metadata.name} `} />
          </Grid>
          {content.map(({ label, value, sm = 12, md, lg, contentStyles }, index) => (
            <Grid item key={index} sm={sm as GridSize} md={md as GridSize} lg={lg as GridSize}>
              <Typography variant="h2" className={contentStyles?.label || classes.label}>
                {label}
              </Typography>
              <Typography variant="body2" className={contentStyles?.value || classes.value}>
                {value || <Typography>&nbsp;</Typography>}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
