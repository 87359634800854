import _ from 'lodash';

export const ERROR_MESSAGES = {
  E_CREDENTIALS_RECORD_BEING_USED: 'Credentials record being used',
  E_CREDENTIALS_RECORD_CREATION_CONFLICT: 'Credentials record creation conflict. Please, try another name',
  E_CREDENTIALS_RECORD_NOT_FOUND: 'Credentials record not found',
  E_CREDENTIALS_TYPE_NOT_APPLICABLE: 'Credentials type not applicable',
  E_EVENT_TYPE_IS_NOT_SUPPORTED: 'Event type is not supported',
  E_FORM_URLENCODED_CONTENT_ENCODING_EXPECTED: '"application/x-www-form-urlencoded" content encoding expected ',
  E_NO_REGISTRY_CONFIGURATION_FOUND: 'Registry configuration invalid or not found',
  E_NO_INTEGRATION_FOUND_FOR_ORGANIZATION: 'No integration found for organization',
  E_NO_INSTALLED_APPLICATIONS_FOUND_FOR_INTEGRATION: 'No installed applications found for integration',
  E_CANNOT_FIND_ARGO_INSTANCE_TO_MATCH_THE_CLUSTER: 'Cannot find argo instance to match the cluster',
  E_COULD_NOT_GENERATE_TOKEN: 'Could not generate token',
};

export type ConfiguratorErrorMessage = keyof typeof ERROR_MESSAGES;

export const ERROR_MESSAGES_MAP = _.reduce<ConfiguratorErrorMessage, Record<ConfiguratorErrorMessage, ConfiguratorErrorMessage>>(
  _.keys(ERROR_MESSAGES) as ConfiguratorErrorMessage[],
  (acc, curr) => {
    acc[curr] = curr;
    return acc;
  },
  {} as any,
);
