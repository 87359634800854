import { JSONSchema7 } from 'json-schema';
import { CredentialsRecordType } from './types';

export const DOCKER_REGISTRY_TYPE: CredentialsRecordType = 'docker_registry';

export const DOCKER_REGISTRY_SCHEMA: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255 },
    type: { title: 'Secret Type', enum: [DOCKER_REGISTRY_TYPE] },
    data: {
      type: 'object',
      title: '',
      properties: {
        url: { title: 'URL', type: 'string' },
        login: { title: 'Login', type: 'string', minLength: 1, maxLength: 255 },
        password: { title: 'Password', type: 'string', minLength: 1, maxLength: 255 },
        apiUrl: { title: 'API URL', type: 'string', format: 'url' },
      },
      required: ['login', 'password', 'url'],
    },
  },
  required: ['name', 'type', 'data'],
};
