import { RecursivePartial } from '@tmatic/utils';
import _ from 'lodash';
import { constants as C, ResourceType } from '../../config';
import { Installation, ReadyStatus, Resource } from '../../entities';
import { EntityCreatePayload } from './types';

export const K8S_CLUSTER_TARGETS = ['GatewayClass', 'Gateway', 'HTTPRoute', 'ReferenceGrant'];
export const CONTROL_PLANE_TARGETS: string[] = [];

const TARGETS_MAP: Record<string, string[]> = {
  'k8s-cluster': K8S_CLUSTER_TARGETS,
  'control-plane': CONTROL_PLANE_TARGETS,
};

export class ResourceFactory {
  static TYPES = C.RESOURCE_TYPES;

  static mergeWithDefaults(payload: RecursivePartial<Resource>): Resource {
    return _.merge(
      {
        apiVersion: C.BACKSTAGE_IO_V1ALPHA1,
        metadata: { namespace: 'default' },
        spec: {
          system: C.SPEC_DEFAULTS.SYSTEM,
          lifecycle: C.SPEC_DEFAULTS.LIFECYCLE,
        },
      },
      payload,
      { kind: C.KIND_RESOURCE },
    ) as Resource;
  }

  static createFromPayload(payload: EntityCreatePayload<Resource>): Resource {
    return ResourceFactory.mergeWithDefaults({
      metadata: {
        name: payload.name,
        namespace: payload.namespace || 'default',
        title: payload.title,
        annotations: payload.annotations,
        description: payload.description,
      },
      spec: {
        type: payload.type,
        owner: payload.owner,
        system: payload.system,
        ownedBy: payload.owner,
        partOf: payload.partOf || payload.system,
      },
    });
  }

  static initResources(type: ResourceType | string[]): ReadyStatus[] {
    const targets: string[] = _.isArray(type) ? type : TARGETS_MAP[type];
    return _.map(targets, kind => ({
      kind: 'CustomResourceDefinition',
      spec: { group: null, kind },
      status: 'missing',
      lastUpdated: new Date().toISOString(),
    }));
  }

  static initControlPlaneInstallations(): Installation[] {
    const lastUpdated = new Date().toISOString();
    return [
      { name: 'crossplane', title: 'Crossplane', ready: false, lastUpdated },
      { name: 'argocd', title: 'Argo CD', ready: false, lastUpdated },
      { name: 'vault-secrets-operator', title: 'Vault Secrets Operator', ready: false, lastUpdated },
    ];
  }
}
