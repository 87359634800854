import { JSONSchema7 } from 'json-schema';

export const JENKINS_CONFIG_PLACEHOLDER: string = 'jenkins.instances';

export const JENKINS: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'Jenkins' },
    apiKey: {
      type: 'string',
      title: 'Api Key',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    baseUrl: { type: 'string', title: 'Base Url', minLength: 1 },
    username: { type: 'string', title: 'Username', minLength: 1 },
  },
  required: ['name', 'apiKey', 'baseUrl', 'username'],
};
