import { JSONSchema7 } from 'json-schema';

export const GITLAB_CONFIG_PLACEHOLDER: string = 'integrations.gitlab';

export const GITLAB: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'GitLab' },
    host: { type: 'string', title: 'Host', default: 'gitlab.com', minLength: 1 },
    token: {
      type: 'string',
      title: 'Token',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
  },
  required: ['name', 'host', 'token'],
  allOf: [
    {
      if: { properties: { host: { not: { const: 'gitlab.com' } } } },
      then: {
        properties: {
          apiBaseUrl: { type: 'string', title: 'apiBaseUrl', minLength: 1, description: 'This field will be used only if host field value is other than gitlab.com' },
        },
        required: ['apiBaseUrl'],
      },
    },
  ],
};
