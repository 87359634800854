import _ from 'lodash';
import { JSONSchema7 } from 'json-schema';
import { upperCamelToKebab } from '@tmatic/utils';

const hasToHaveTitle = (object: any, key: string) => {
  const hasProperties = _.isObject(object?.properties);
  if (key === 'items' && hasProperties) {
    return false;
  }
  return _.isString(object?.type) || hasProperties || _.isObject(object?.items);
};

interface NormalizeJsonSchemaOptions {
  schema: JSONSchema7;
  path?: string[];
  show?: boolean;
}

export const normalizeJsonSchema = ({ schema, path = [], show }: NormalizeJsonSchemaOptions): JSONSchema7 => {
  return _.mapValues<any, JSONSchema7>(schema, (value, key) => {
    if (show) {
      path.push(key);
    }

    if (_.isArray(value)) {
      return value;
    }

    const setTitle = hasToHaveTitle(value, key);
    if (setTitle) {
      value.title = _.map(upperCamelToKebab(key).split('-'), _.capitalize).join(' ');
    }

    return _.isObject(value) ? normalizeJsonSchema({ schema: value, path: [...path], show }) : value;
  });
};
