import { TableColumn } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  TMATIC_ANNOTATION_COMPONENT_GIT_REPO_DEFAULT_BRANCH,
  TMATIC_ANNOTATION_COMPONENT_GIT_REPO_NAME,
  TMATIC_ANNOTATION_COMPONENT_GIT_REPO_OWNER,
} from '@tmatic/constants-common';
import { CardTMatic } from '../CardTMatic';
import React from 'react';

interface KeyValueObject {
  key: string;
  value: string;
}

const columns: TableColumn<KeyValueObject>[] = [
  { title: 'Key', field: 'key' },
  { title: 'Value', field: 'value' },
];

export const ComponentRepositoryCard = () => {
  const { entity } = useEntity();
  const cardTitle = 'Repository';

  const data = entity
    ? [
        {
          key: 'Organization',
          value: entity.metadata.annotations?.[TMATIC_ANNOTATION_COMPONENT_GIT_REPO_OWNER] ?? 'N/A',
        },
        {
          key: 'Repository Name',
          value: entity.metadata.annotations?.[TMATIC_ANNOTATION_COMPONENT_GIT_REPO_NAME] ?? 'N/A',
        },
        {
          key: 'Component Default Branch',
          value: entity.metadata.annotations?.[TMATIC_ANNOTATION_COMPONENT_GIT_REPO_DEFAULT_BRANCH] ?? 'N/A',
        },
        // TODO need update property when created entity
        {
          key: 'Created',
          value: 'N/A',
        },
        {
          key: 'Updated',
          value: 'N/A',
        },
      ]
    : [];

  return <CardTMatic<KeyValueObject> data={data} cardTitle={cardTitle} columns={columns} />;
};
