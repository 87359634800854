import { getClaimDefaults, Resource } from '@tmatic/entity-management-common';
import { TMATIC_ANNOTATION } from '@tmatic/constants-common';
import { JsonSchemaForm } from '@tmatic/json-schema-form-react';
import { RecursivePartial, safeJsonParse } from '@tmatic/utils';
import React, { useState } from 'react';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { useAsync } from 'react-use';

export interface ClaimFormProps<FormData extends object> {
  controlPlaneName: string;
  resource: Resource;
  formData?: RecursivePartial<FormData>;
  onClose: () => void;
}

export function ClaimForm<FormData extends object>(props: ClaimFormProps<FormData>) {
  const { controlPlaneName, resource, formData, onClose } = props;
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const alertApi = useApi(alertApiRef);
  const navigate = useNavigate();

  const prefix = `/catalog/cp-${controlPlaneName}/resource/`;
  const { pathname } = useLocation();
  const isEditPage = pathname.startsWith(prefix);
  const [loading, setLoading] = useState(false);
  const submitForm = (data: FormData) => {
    setLoading(true);
    catalogApi
      .performCustomRequest<string, Resource>({
        method: isEditPage ? 'PATCH' : 'POST',
        url: { suffix: `k8s/${controlPlaneName}/xrds/${resource.metadata.name}` },
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
      .then(result => {
        alertApi.post({ severity: 'success', message: 'Success!' });
        const targetPage = `${prefix}${result.metadata.name}`;
        if (pathname !== targetPage) {
          navigate(targetPage);
        }
      })
      .catch(error => errorApi.post(error))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const groups = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'Group' } });
    return _.map(result.items, 'metadata.name');
  }, []);

  const systems = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'System' } });
    return _.map(result.items, 'metadata.name');
  }, []);

  const patch: JSONSchema7 = _.merge(getClaimDefaults(), {
    properties: {
      metadata: {
        properties: {
          name: {},
          description: {},
          owner: _.size(groups.value) ? { enum: groups.value } : {},
          system: _.size(systems.value) ? { enum: systems.value } : {},
        },
      },
    },
  });

  const schema = _.merge(patch, safeJsonParse(resource.metadata?.annotations?.[TMATIC_ANNOTATION.CROSSPLANE_RESOURCE_SPEC_VERSIONS_SCHEMA] as string, {}));
  return <JsonSchemaForm formData={formData} schema={schema} onSubmit={submitForm} onClose={onClose} loading={loading || groups.loading || systems.loading} />;
}
