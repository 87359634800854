import { JSONSchema7 } from 'json-schema';
import { CredentialsRecordType } from './types';

import { DOCKER_REGISTRY_SCHEMA, DOCKER_REGISTRY_TYPE } from './docker-registry';
import { DOCKER_REGISTRY_AWS_SCHEMA, DOCKER_REGISTRY_AWS_TYPE } from './docker-registry-aws';

export * from './docker-registry';
export * from './docker-registry-aws';
export * from './types';

export const CREDENTIALS_SCHEMAS: Record<
  Uppercase<CredentialsRecordType>,
  {
    schema: JSONSchema7;
    type: CredentialsRecordType;
  }
> = {
  DOCKER_REGISTRY: {
    schema: DOCKER_REGISTRY_SCHEMA,
    type: DOCKER_REGISTRY_TYPE,
  },
  DOCKER_REGISTRY_AWS: {
    schema: DOCKER_REGISTRY_AWS_SCHEMA,
    type: DOCKER_REGISTRY_AWS_TYPE,
  },
};
