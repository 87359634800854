import { JSONSchema7 } from 'json-schema';

export const NEWRELIC_CONFIG_PLACEHOLDER: string = 'proxy./newrelic/apm/api';

export const NEWRELIC_DASHBOARD_CONFIG_PLACEHOLDER: string = 'proxy./newrelic/api';

export const NEWRELIC: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'NewRelic' },
    headers: {
      type: 'object',
      title: '',
      properties: {
        'X-Api-Key': {
          type: 'string',
          title: 'API key',
          minLength: 1,
          description: 'This field contains sensitive information. On edit screen its content will be hidden',
        },
      },
      required: ['X-Api-Key'],
    },
    target: {
      type: 'string',
      title: 'Target',
      minLength: 1,
    },
  },
  required: ['name', 'headers', 'target'],
};
