import { ANNOTATION_EDIT_URL, ANNOTATION_LOCATION, parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { InfoCardVariants, Link } from '@backstage/core-components';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { TMATIC_ANNOTATION } from '@tmatic/constants-common';
import { Resource } from '@tmatic/entity-management-common';
import { safeYamlLoad } from '@tmatic/utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import { BackstageTheme } from '@backstage/theme';
import { useAsync } from 'react-use';
import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { ClaimFormModal } from '../ClaimForm';
import { useSubHeaderLinks } from './useSubHeaderLinks';
import { HeaderIconLinkRowTMatic } from './HeaderIconLinkRowTMatic';
import { AboutContentTMatic } from './AboutContentTMatic';

const useStyles = makeStyles({
  header: {
    paddingBottom: '1rem',
  },
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: { flex: 1 },
  fullHeightCardContent: { flex: 1 },
});

type gridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface AboutCardTMaticProps {
  variant?: InfoCardVariants;
  grid?: Partial<{ [key in 'xs' | 'sm' | 'md' | 'lg' | 'xl']: gridSize }>;
}

/**
 * Exported publicly via the EntityAboutCard
 */
export const AboutCardTMatic = (props: AboutCardTMaticProps) => {
  const { variant = 'gridItem' } = props;
  const classes = useStyles();
  const theme = useTheme<BackstageTheme>();
  const { entity } = useEntity();
  const modalBtnRef = useRef<any>(null);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);
  const entityMetadataEditUrl = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  // Limiting the ability to manually refresh to the less expensive locations
  const allowRefresh = entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        display: 'transient',
      });
    } catch (e) {
      errorApi.post(e);
    }
  }, [catalogApi, alertApi, errorApi, entity]);

  // variables extracted from the annotations below related only for crossplane claims resources
  const cpName = entity.metadata?.annotations?.[TMATIC_ANNOTATION.CONTROL_PLANE_NAME];
  const isClaim = entity.kind === 'Resource' && entity.metadata?.annotations?.[TMATIC_ANNOTATION.CROSSPLANE_RESOURCE_API_KIND] === 'claim';
  const xrdRef = entity.metadata?.annotations?.[TMATIC_ANNOTATION.CROSSPLANE_RESOURCE_COMPOSITE_RESOURCE_DEFINITION_REF];

  const xrd = useAsync(async () => {
    if (isClaim && _.isString(xrdRef)) {
      return catalogApi.getEntityByRef(xrdRef);
    }
    return undefined;
  }, [isClaim, xrdRef, cpName]);

  const links = useSubHeaderLinks();

  const actions: JSX.Element[] = [];
  if (allowRefresh && !isClaim) {
    actions.push(
      <IconButton aria-label="Refresh" title="Schedule entity refresh" onClick={refreshEntity}>
        <CachedIcon />
      </IconButton>,
    );
  }

  if (isClaim) {
    if (xrd.value) {
      actions.push(
        <IconButton
          to=""
          component={Link}
          aria-label="Edit"
          title="Edit Claim"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            modalBtnRef?.current?.openModal?.();
          }}
        >
          <EditIcon />
        </IconButton>,
      );
    }
  } else if (entity.kind.toLocaleLowerCase('en-US') === 'api') {
    const editorLink = `/catalog/${entity.metadata.namespace}/api/${entity.metadata.name}/editor`;
    actions.push(
      <IconButton component={Link} aria-label="Edit" title="Edit" to={editorLink}>
        <EditIcon />
      </IconButton>,
    );
  } else if (entity.kind.toLocaleLowerCase('en-US') === 'application') {
    /* empty */
  } else {
    actions.push(
      <IconButton component={Link} aria-label="Edit" disabled={!entityMetadataEditUrl} title="Edit Metadata" to={entityMetadataEditUrl ?? '#'}>
        <EditIcon />
      </IconButton>,
    );
  }

  let claimModal: JSX.Element | null = null;
  if (cpName && xrd.value) {
    const formData = _.merge(safeYamlLoad(entity.spec?.definition as string, {}), {
      metadata: {
        owner: parseEntityRef(entity.spec?.owner as string).name,
        system: parseEntityRef(entity.spec?.system as string).name,
      },
    });
    claimModal = <ClaimFormModal controlPlaneName={cpName} resource={xrd.value as Resource} ref={modalBtnRef} formData={formData} />;
  }

  let subheader: React.ReactNode;
  if (_.size(actions)) {
    subheader = <HeaderIconLinkRowTMatic>{...links}</HeaderIconLinkRowTMatic>;
  }

  const styles: React.CSSProperties = {};
  if (theme.palette.type === 'dark') {
    styles.backgroundColor = '#28293c';
  }

  const view = (
    <Card className={cardClass}>
      {claimModal}
      <CardHeader style={{ ...styles }} className={classes.header} title="About" action={<>{...actions}</>} subheader={subheader} />
      <Divider />
      <CardContent style={{ ...styles }} className={cardContentClass}>
        <AboutContentTMatic entity={entity} />
      </CardContent>
    </Card>
  );

  if (props.grid) {
    return (
      <Grid item {...props.grid}>
        {view}
      </Grid>
    );
  }

  return view;
};
