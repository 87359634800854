import { useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useCreateApplication } from '../../hooks';
import { CreateApplicationModal } from '../modals';
// copy-pasted instead of importing { GridJustification } from '@material-ui/core/Grid/Grid';
export type GridJustification = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';

export interface CreateApplicationProps {
  className?: string;
  title?: string;
  variant?: 'contained' | 'text' | 'outlined';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  style?: React.CSSProperties;
  justifyContent?: GridJustification;
}

const useEntitySafely = () => {
  try {
    return useEntity().entity;
  } catch (e) {
    return undefined;
  }
};

export const CreateApplicationButton = (props: CreateApplicationProps) => {
  const { title = 'New Application', className, variant = 'contained', color = 'primary', style, justifyContent } = props;

  const entity = useEntitySafely();
  let initialState: any;
  if (entity) {
    initialState = {
      templateRef: 'template:default/run-application',
      values: {
        system: '',
        owner: '',
        description: '',
        artifactName: stringifyEntityRef(entity),
        argoCDInstance: '',
        argoCDProject: '',
        clusterName: '',
        namespace: '',
        deploymentName: '',
        repoUrl: 'github.com?repo=argocd',
      },
    };
  }

  const createApplication = useCreateApplication();
  const content = [
    <Button className={className} variant={variant} color={color as any} onClick={() => createApplication.setShowModal(true)}>
      {title}
    </Button>,
    <CreateApplicationModal
      open={createApplication.showModal}
      toggleModal={() => createApplication.setShowModal(!createApplication.showModal)}
      onSubmit={createApplication.onSubmit}
      initialState={initialState}
    />,
  ];

  return !justifyContent ? (
    <>{...content}</>
  ) : (
    <Grid item justifyContent={justifyContent} xs={12} style={style}>
      {...content}
    </Grid>
  );
};
