import React from 'react';
import { ApiEntity, RELATION_PART_OF } from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { CodeSnippet, InfoCard, InfoCardVariants, Progress, TableColumn, TableProps, WarningPanel } from '@backstage/core-components';
import { apiEntityColumnsReduced } from '../presets';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';

const tableOptions: TableProps['options'] = {
  paging: false,
  search: false,
  draggable: false,
  sorting: false,
  padding: 'dense',
};

export const ProvidedApisCard = (props: { variant?: InfoCardVariants; columns?: TableColumn<ApiEntity>[] }) => {
  const { variant = 'gridItem', columns = apiEntityColumnsReduced } = props;
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_PART_OF,
  });
  const cardTitle = 'Provided APIs';

  if (loading) {
    return (
      <InfoCard variant={variant} title="Provided APIs">
        <Progress />
      </InfoCard>
    );
  }

  if (error || !_.size(entities)) {
    let title: string;
    let severity: 'error' | 'warning';
    let message: React.ReactNode;

    if (!_.size(entities)) {
      title = 'There are no APIs';
      severity = 'warning';
    } else {
      title = 'Could not load APIs';
      severity = 'error';
      message = <CodeSnippet text={`${error}`} language="text" />;
    }
    return (
      <InfoCard variant={variant} title="Provided APIs">
        <WarningPanel severity={severity} title={title} message={message} />
      </InfoCard>
    );
  }

  return <CardTMatic<ApiEntity> data={entities as ApiEntity[]} cardTitle={cardTitle} columns={columns} options={tableOptions} />;
};
