import { Select, SelectedItems, SelectItem } from '@backstage/core-components';
import React from 'react';
import { useGitRepoOrganizations } from './useGitRepoOrganizations';

export interface RepoUrlPicketDropdownProps {
  label: string;
  getLabelForSelect: (text: string) => string;
  items: SelectItem[];
  onChange: (selected: SelectedItems) => void;
  value: string;
  disabled?: boolean;
  uiSchema: any;
}

export const RepoUrlPickerDropdown = ({ uiSchema, disabled, value, onChange, items, label, getLabelForSelect }: RepoUrlPicketDropdownProps) => {
  // hook is needed to add credentials to the payload
  useGitRepoOrganizations({ uiSchema, repositoryUrl: value });
  return <Select label={getLabelForSelect(label)} items={items} onChange={onChange} selected={value} disabled={disabled} />;
};
