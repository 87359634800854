import { RecursivePartial } from '@tmatic/utils';
import _ from 'lodash';
import { constants as C } from '../../config';
import { Artifact } from '../../entities';
import { EntityCreatePayload } from './types';

export class ArtifactFactory {
  static TYPES = C.ARTIFACT_TYPES;

  static mergeWithDefaults(payload: RecursivePartial<Artifact>): Artifact {
    return _.merge(
      {
        apiVersion: C.TRANSFORMATIC_IO_V1ALPHA1,
        metadata: { namespace: 'default' },
        spec: {
          system: C.SPEC_DEFAULTS.SYSTEM,
          lifecycle: C.SPEC_DEFAULTS.LIFECYCLE,
        },
      },
      payload,
      { kind: C.KIND_ARTIFACT },
    ) as Artifact;
  }

  static createFromPayload(payload: EntityCreatePayload<Artifact>): Artifact {
    return ArtifactFactory.mergeWithDefaults({
      metadata: {
        name: payload.name,
        namespace: payload.namespace || 'default',
        title: payload.title,
        annotations: payload.annotations,
        description: payload.description,
      },
      spec: {
        type: payload.type,
        owner: payload.owner,
        system: payload.system,
        ownedBy: payload.owner,
        partOf: payload.partOf || payload.system,
      },
    });
  }
}
