import { BackstageTheme } from '@backstage/theme';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import classnames from 'classnames';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    label: {
      color: theme.palette.type === 'dark' ? '#fff' : '#000',
      fontSize: '14px',
      transform: 'initial',
      fontFamily: 'Inter',
      fontWeight: 'bold',
    },
    // does not make impact because of label
    pullLeft: {
      position: 'relative',
      left: '-14px',
    },
    pullRight: {
      paddingLeft: '14px',
    },
  };
});

interface CustomFormLabelProps {
  text: string;
  comment?: string;
  pull?: 'left' | 'right';
}

export const CustomFormLabel = ({ comment, text, pull }: CustomFormLabelProps) => {
  const classes = useStyles();
  let className: string;
  switch (pull) {
    case 'left': {
      className = classnames(classes.label, classes.pullLeft);
      break;
    }
    case 'right': {
      className = classnames(classes.label, classes.pullRight);
      break;
    }
    default: {
      className = classes.label;
    }
  }
  return (
    <Typography className={className}>
      {text}
      {!comment ? null : (
        <>
          &nbsp;<small>{comment}</small>
        </>
      )}
    </Typography>
  );
};
