import { AddClusterPayload, ErrorObject, validate } from '@tmatic/configurator-common';
import { JSONSchema7 } from 'json-schema';
import { CreateClusterData } from './types';

export const CreateK8SClusterSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    owner: { type: 'string', minLength: 1 },
    system: { type: 'string', minLength: 1 },
    description: { type: 'string', maxLength: 1022 },
    clusterData: {
      type: 'object',
      properties: {
        name: { type: 'string', format: 'k8s-name', minLength: 1 },
      },
      required: ['name'],
    },
  },
  required: ['owner', 'system', 'clusterData'],
};

export const validateRequestPayload = (data: AddClusterPayload<CreateClusterData>): ErrorObject[] | null => {
  return validate(CreateK8SClusterSchema, data);
};
