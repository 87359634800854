import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy } from './BaseEntity.schema';

export const UserSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind', 'properties.spec.properties.type']), {
  properties: {
    kind: { const: C.KIND_USER },
    spec: {
      properties: {
        profile: {
          properties: {
            displayName: { type: 'string' },
            email: { type: 'string' },
            picture: { type: 'string' },
          },
        },
        memberOf: {
          type: 'array',
          items: { type: 'string' },
        },
      },
    },
  },
});
