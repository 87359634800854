import { JSONSchema7 } from 'json-schema';
import { CredentialsRecordType } from './types';

export const DOCKER_REGISTRY_AWS_TYPE: CredentialsRecordType = 'docker_registry_aws';

export const DOCKER_REGISTRY_AWS_SCHEMA: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255 },
    type: { title: 'Secret Type', enum: [DOCKER_REGISTRY_AWS_TYPE] },
    data: {
      type: 'object',
      title: '',
      properties: {
        accountId: { title: 'Account ID', type: 'string' },
        region: { title: 'Region', type: 'string' },
        accessKeyId: { title: 'Access Key ID', type: 'string', minLength: 1, maxLength: 255 },
        secretAccessKey: { title: 'Secret Access Key', type: 'string', minLength: 1, maxLength: 255 },
        apiUrl: { title: 'API URL', type: 'string', format: 'url' },
      },
      required: ['accessKeyId', 'secretAccessKey', 'accountId', 'region'],
    },
  },
  required: ['name', 'type', 'data'],
};
