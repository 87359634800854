import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WidgetProps } from '@rjsf/utils';
import React, { useState } from 'react';
import { useStyles } from './common';

export const CheckboxArrayTemplate = (props: WidgetProps<any, any>) => {
  const { formData, schema, onChange } = props;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedEnums, setSelectedEnums] = useState<Array<string>>([]);

  // todo: ensure filter logic is correct
  const filteredItems = schema?.items?.enum?.filter((_item: any, index: number) =>
    schema?.items?.enumNames?.[index].toLocaleLowerCase('en-US').includes(searchTerm.toLocaleLowerCase('en-US')),
  );
  const filteredLabels = schema?.items?.enumNames?.filter((_item: any, index: number) =>
    schema?.items?.enumNames?.[index].toLocaleLowerCase('en-US').includes(searchTerm.toLocaleLowerCase('en-US')),
  );
  const handleChange = (index: number) => {
    const updatedFormData = formData || [];
    let updatedValue = [];
    const valueToChange = filteredItems[index];
    if (updatedFormData.includes(valueToChange)) {
      updatedValue = updatedFormData.filter((val: string) => val !== valueToChange);
      const newSelected = selectedEnums.filter(val => val !== valueToChange);
      setSelectedEnums(newSelected);
    } else {
      updatedValue = [...updatedFormData, valueToChange];
      const newSelected = [...selectedEnums, valueToChange];
      setSelectedEnums(newSelected);
    }

    onChange(updatedValue);
  };

  const handleSelectedChange = (value: string) => {
    const updatedFormData = formData || [];
    const updatedValue = updatedFormData.includes(value) ? updatedFormData.filter((val: string) => val !== value) : [...updatedFormData, value];

    onChange(updatedValue);
  };

  const scrollToActions = () => {
    const formActions = document.getElementById('form-actions');
    if (formActions) {
      formActions.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <TextField label="Search" variant="outlined" placeholder="Enter the name" value={searchTerm} className={classes.search} onChange={e => setSearchTerm(e.target.value)} />
        <Button variant="outlined" color="info" className={classes.cta} onClick={scrollToActions}>
          To submit
        </Button>
      </div>
      <div className={classes.selectedGroup}>
        {!formData || !formData.length ? (
          <Typography>You haven't selected any integration</Typography>
        ) : (
          <>
            <Typography>You have selected next integrations:</Typography>
            {formData?.map((item: string) => {
              const index = schema?.items?.enum.indexOf(item);
              return (
                <FormControlLabel
                  // originally was
                  // control={<Checkbox checked={true} />}
                  control={<Checkbox checked />}
                  label={schema?.items?.enumNames[index]}
                  value={item}
                  key={`selected-${index}`}
                  onChange={() => handleSelectedChange(item)}
                />
              );
            })}
          </>
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.selectedGroup}>
        <Typography>Available integrations:</Typography>
      </div>
      <FormGroup className={classes.flexContainer}>
        {filteredItems?.map((item: string, index: number) => (
          <FormControlLabel
            control={<Checkbox checked={(formData || []).includes(item)} />}
            label={filteredLabels[index]}
            value={item}
            key={`dockerhub-repo-${index}`}
            onChange={() => handleChange(index)}
          />
        ))}
      </FormGroup>
    </div>
  );
};
