import { useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import _ from 'lodash';

const mapToLabelValue = (items: Entity[]) => _.map(items, ({ metadata: { name } }) => ({ label: name, value: name }));

export const useSpecRequestedSelection = () => {
  const catalogApi = useApi(catalogApiRef);
  const groups = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'Group' } });
    return mapToLabelValue(result.items);
  }, []);

  const systems = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'System' } });
    return mapToLabelValue(result.items);
  }, []);

  return {
    groups,
    systems,
  };
};
