import { Format, State, Action } from './types';

export const STATE_FORMAT: { [key in Uppercase<Format>]: Format } = {
  FILE: 'file',
  TEXT: 'text',
  URL: 'url',
};

export const initialState: State = {
  format: STATE_FORMAT.FILE,
  value: '',
};

export const reducer = (state: State, action?: Action): { format: Format; value: string } => {
  switch (action?.type) {
    case 'setFormat':
      return { ...state, format: action.value };
    case 'setValue':
      return { ...state, value: action.value };
    case 'merge':
      return { ...state, ...action.value };
    default:
      return initialState;
  }
};
