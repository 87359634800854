import { EntityTable, useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { ComponentEntity, RELATION_API_CONSUMED_BY } from '@backstage/catalog-model';
import { CardTMatic } from '../CardTMatic';
import React from 'react';

export const HasComponentCard = () => {
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_API_CONSUMED_BY,
  });

  const cardTitle = 'Components';

  return (
    <CardTMatic<ComponentEntity> data={entities as ComponentEntity[]} cardTitle={cardTitle} columns={EntityTable.componentTMaticEntityColumns} loading={loading} error={error} />
  );
};
