import React from 'react';
import { ComponentEntity, RELATION_PART_OF } from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { componentEntityColumns } from '../presets';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';

export const ParentComponentsCard = (props: { columns?: TableColumn<ComponentEntity>[] }) => {
  const { columns = componentEntityColumns } = props;
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_PART_OF,
  });
  const cardTitle = 'Parent components';

  const rowData = _.filter(entities, item => item.kind.toLocaleLowerCase('en-US') === 'component');

  return <CardTMatic<ComponentEntity> data={rowData as ComponentEntity[]} cardTitle={cardTitle} columns={columns} options={{ paging: true }} loading={loading} error={error} />;
};
