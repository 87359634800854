import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy } from './BaseEntity.schema';

export const ResourceSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind']), {
  properties: {
    kind: { const: C.KIND_RESOURCE },
    spec: {
      properties: {
        type: { type: 'string', minLength: 1 },
        source: { type: 'string', minLength: 1 },
        sourceDefinedType: { type: 'string', minLength: 1 },
      },
    },
    status: {
      properties: {
        resources: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              kind: { type: 'string' },
              spec: {
                type: 'object',
                properties: {
                  group: { type: ['string', 'null'] },
                  kind: { type: 'string' },
                },
                required: ['group', 'kind'],
              },
              status: { type: 'string', enum: ['missing', 'error', 'warning', 'success'] },
              message: { type: 'string' },
              lastUpdated: { type: 'string' },
            },
            required: ['kind', 'spec', 'status', 'lastUpdated'],
          },
        },
      },
    },
  },
});
