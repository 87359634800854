import { UiSchema } from '@rjsf/core';
import { ArrayFieldTemplate } from './ArrayFieldTemplate';
import { TitleFieldTemplate } from './TitleFieldTemplate';
import { ObjectCardTemplate } from './ObjectCardTemplate';

export * from './ArrayFieldTemplate';
export * from './CheckboxArrayTemplate';
export * from './DescriptionAlertTemplate';
export * from './HiddenInput';
export * from './MultiSelectField';
export * from './ObjectCardTemplate';
export * from './ObjectFieldAccordionTemplate';
export * from './ObjectFieldTemplate';
export * from './TitleFieldTemplate';

export const UI_DEFAULTS: UiSchema = {
  'ui:ArrayFieldTemplate': ArrayFieldTemplate,
  'ui:TitleFieldTemplate': TitleFieldTemplate,
  'ui:ObjectFieldTemplate': ObjectCardTemplate,
};

type ExistingCustomizations = 'CLAIM_FORM';

export const UI_CUSTOMIZATIONS: Record<ExistingCustomizations, UiSchema> = {
  CLAIM_FORM: {
    spec: { 'ui:title': 'Composite Resource Definition' },
  },
};
