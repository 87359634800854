import { BackstageTheme } from '@backstage/theme';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(
  () => {
    const theme = useTheme<BackstageTheme>();
    return {
      links: {
        margin: theme.spacing(2, 0) || '10px 0',
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'min-content',
        gridGap: theme.spacing(3),
      },
    };
  },
  { name: 'BackstageHeaderIconLinkRowTMatic' },
);

export function HeaderIconLinkRowTMatic(props: PropsWithChildren) {
  const classes = useStyles();
  return <nav className={classes.links}>{props.children}</nav>;
}
