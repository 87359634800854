import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import React from 'react';

export function DescriptionAlertTemplate(props: { text: string }) {
  return (
    <Alert severity="warning">
      <Typography>{props.text}</Typography>
    </Alert>
  );
}
