import { HttpError } from '@tmatic/utils';
import { JSONSchema7 as JSONSchema } from 'json-schema';
import { APISchema } from './API.schema';
import { ApplicationSchema } from './Application.schema';
import { ArtifactSchema } from './Artifact.schema';
import { ComponentSchema } from './Component.schema';
import { DomainSchema } from './Domain.schema';
import { GroupSchema } from './Group.schema';
import { ResourceSchema } from './Resource.schema';
import { UserSchema } from './User.schema';
import { TemplateSchema } from './Template.schema';
import { SystemSchema } from './System.schema';
import { LocationSchema } from './Location.schema';
import { constants as C, Kind } from '../config';
import Ajv from 'ajv';

export type { API } from './API';
export type { Application } from './Application';
export type { Artifact } from './Artifact';
export type { BaseEntity } from './BaseEntity';
export type { Component } from './Component';
export type { Domain } from './Domain';
export type { Group } from './Group';
export type { Resource, ReadyStatus, Condition, Installation } from './Resource';
export type { User } from './User';
export type { Template } from './Template';
export type { System } from './System';
export type { Location } from './Location';

const kindSchemaMap: Record<Kind, JSONSchema> = {
  [C.KIND_API]: APISchema,
  [C.KIND_APPLICATION]: ApplicationSchema,
  [C.KIND_ARTIFACT]: ArtifactSchema,
  [C.KIND_COMPONENT]: ComponentSchema,
  [C.KIND_DOMAIN]: DomainSchema,
  [C.KIND_GROUP]: GroupSchema,
  [C.KIND_RESOURCE]: ResourceSchema,
  [C.KIND_USER]: UserSchema,
  [C.KIND_TEMPLATE]: TemplateSchema,
  [C.KIND_SYSTEM]: SystemSchema,
  [C.KIND_LOCATION]: LocationSchema,
};

export const validateEntity = (payload: any): null | HttpError => {
  const kind = (payload as any)?.kind;
  if (!kind) {
    return new HttpError(400, 'Invalid request payload');
  }
  const schema = kindSchemaMap[kind as Kind];
  if (!schema) {
    return new HttpError(400, 'Unknown entity kind');
  }

  const ajv = new Ajv({ allowUnionTypes: true });
  const validate = ajv.compile(schema);
  if (!validate(payload)) {
    return new HttpError(422, 'Validation failed', validate.errors);
  }

  return null;
};
