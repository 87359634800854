import { KebabToSnakeCase } from '@tmatic/utils';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';

export const ARGOCD_CONFIG_PLACEHOLDER: string = 'argocd.appLocatorMethods.0.instances';

export const ARGOCD_ACCESS_MODE: Record<Uppercase<KebabToSnakeCase<ArgoCDAccessMode>>, ArgoCDAccessMode> = {
  HTTP: 'http',
  K8S_API: 'k8s-api',
};

export const ARGOCD: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'ArgoCD' },
    url: { type: 'string', title: 'URL', minLength: 1 },
    token: {
      type: 'string',
      title: 'Token',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    accessMode: { type: 'string', enum: _.values(ARGOCD_ACCESS_MODE), default: ARGOCD_ACCESS_MODE.HTTP },
    clusterRef: { type: 'string' },
    nsRegexp: { type: 'string', title: 'RegExp for new namespaces', minLength: 1 },
    namespaces: {
      type: 'array',
      title: 'Allowed namespaces to deploy',
      items: { title: 'Namespace', type: 'string' },
    },
  },
  required: ['name', 'url', 'token', 'nsRegexp', 'namespaces'],
};

export type ArgoCDAccessMode = 'http' | 'k8s-api';

export interface ArgoCDConfigRecord {
  uid?: string;
  name: string;
  url: string;
  token: string;
  accessMode: ArgoCDAccessMode;
  clusterRef?: string; // used with ArgoCDAccessMode k8s-api
  nsRegexp?: string[];
  namespaces?: string[];
}

export const readonly = (record: ArgoCDConfigRecord) => record.accessMode === ARGOCD_ACCESS_MODE.K8S_API;
