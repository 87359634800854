import { configApiRef, errorApiRef, useAnalytics, useApi } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { ScmIntegrationIconProps } from '@backstage/integration-react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import MaterialLink, { LinkProps as MaterialLinkProps } from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import classnames from 'classnames';
import _ from 'lodash';
import React, { ComponentType, ElementType } from 'react';
import { createRoutesFromChildren, Link as RouterLink, LinkProps as RouterLinkProps, Route } from 'react-router-dom';
import { isExternalUri, getResolvedPath, SCRIPT_PROTOCOL_PATTERN } from './utils';
import { CreateApplicationModal } from '../modals';
import AppsIcon from '@material-ui/icons/Apps';
import { CreateApplication } from '../../hooks';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

export type LinkProps = Omit<MaterialLinkProps, 'to'> &
  Omit<RouterLinkProps, 'to'> & {
    to: string;
    component?: ElementType;
    noTrack?: boolean;
  };

const useStyles = makeStyles(
  () => {
    const theme = useTheme<BackstageTheme>();
    return {
      visuallyHidden: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        height: 1,
        width: 1,
      },
      externalLink: {
        position: 'relative',
        textAlign: 'center',
      },
      label: {
        textTransform: 'uppercase',
        letterSpacing: 1.2,
        display: 'block',
        textAlign: 'center',
        minWidth: '60px',
      },
      disabled: {
        color: theme.palette.text.secondary,
        cursor: 'default',
        textAlign: 'center',
      },
      jsLink: {
        textAlign: 'center',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    };
  },
  { name: 'Link' },
);

type OnClickHandler = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLAnchorElement>) => void;
type IconArgument = ((props: ScmIntegrationIconProps) => JSX.Element) | ComponentType | OverridableComponent<SvgIconTypeMap>;

export interface LinkTMaticProps {
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  icon: IconArgument;
  iconArgs?: Record<string, any>;
  label: string;
  onClick?: OnClickHandler;
}

export interface StandardLinkTMaticProps extends LinkTMaticProps {
  href?: string;
  target?: '_self' | '_blank' | '_parent' | '_top' | string | undefined;
}

export interface JSLinkTMaticProps extends LinkTMaticProps {
  onClick: OnClickHandler;
}

export const StandardLinkTMatic: React.FC<StandardLinkTMaticProps> = ({ label, icon: Icon, iconArgs, href, target, disabled, onClick }) => {
  const classes = useStyles();
  const analytics = useAnalytics();
  let to: string;
  const config = useApi(configApiRef);
  const error = useApi(errorApiRef);
  if (_.isString(href)) {
    to = isReactRouterBeta() ? getResolvedPath(config.getOptionalString('app.baseUrl'), href) : href;
  } else {
    to = '#';
  }

  const linkText = label;
  const external = isExternalUri(to);
  const newWindow = external && /^https?:/.test(to);

  if (SCRIPT_PROTOCOL_PATTERN.test(to)) {
    error.post(new Error('Link component rejected javascript: URL as a security precaution'));
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onClick?.(event);
    if (!disabled && !target && !external && !isReactRouterBeta()) {
      event.preventDefault();
    }
    if (!disabled && !target && !external && isReactRouterBeta()) {
      analytics.captureEvent('click', linkText, { attributes: { to } });
    }
  };

  if (external) {
    return (
      <MaterialLink
        href={to}
        target={newWindow ? '_blank' : undefined}
        rel={newWindow ? 'noopener' : undefined}
        onClick={handleClick}
        className={classnames(classes.externalLink, disabled && classes.disabled)}
      >
        <Icon {...iconArgs} />
        <Typography variant="caption" component="span" className={classes.label}>
          {label}
        </Typography>
        {newWindow && (
          <Typography component="span" className={classes.visuallyHidden}>
            , Opens in a new window
          </Typography>
        )}
      </MaterialLink>
    );
  }

  return (
    <MaterialLink component={RouterLink} to={to} onClick={handleClick} className={classnames(classes.label, disabled && classes.disabled)}>
      <Icon {...iconArgs} />
      <Typography variant="caption" component="span" className={classes.label}>
        {label}
      </Typography>
    </MaterialLink>
  );
};

export const JSLinkTMatic: React.FC<JSLinkTMaticProps> = ({ label, icon: Icon, iconArgs, disabled, onClick }) => {
  const classes = useStyles();
  const theme = useTheme<BackstageTheme>();
  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick(event);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={clickHandler}
      onKeyDown={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          onClick(event);
        }
      }}
      style={{
        cursor: disabled ? 'default' : 'pointer',
        color: theme.palette.link,
      }}
      className={classes.jsLink}
    >
      <Icon {...iconArgs} />
      <Typography variant="caption" component="span" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
};

export const DisabledLinkTMatic: React.FC<Pick<StandardLinkTMaticProps, 'label' | 'icon' | 'iconArgs'>> = ({ label, icon: Icon, iconArgs }) => {
  const classes = useStyles();
  return (
    <div style={{ cursor: 'default', textAlign: 'center' }}>
      <Icon {...iconArgs} />
      <Typography variant="caption" component="span" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
};

export const linkElementFactory = ({ label, icon, href, target, disabled = false, onClick, iconArgs }: StandardLinkTMaticProps) => {
  if (_.isString(href)) {
    return <StandardLinkTMatic label={label} icon={icon} href={href} target={target} disabled={disabled} onClick={onClick} iconArgs={iconArgs} />;
  }

  if (_.isFunction(onClick)) {
    return <JSLinkTMatic label={label} icon={icon} disabled={disabled} onClick={onClick} iconArgs={iconArgs} />;
  }

  return <DisabledLinkTMatic label={label} icon={icon} iconArgs={iconArgs} />;
};

export const linkModalCreateApp = (props: {
  entity: Entity;
  createApplication: CreateApplication;
  showModalCreateApp: boolean;
  setShowModalCreateApp: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { createApplication, entity, showModalCreateApp, setShowModalCreateApp } = props;
  const handleToggleModal = () => {
    setShowModalCreateApp(prev => !prev);
  };

  const initialState = {
    templateRef: 'template:default/run-application',
    values: {
      system: '',
      owner: '',
      description: '',
      artifactName: stringifyEntityRef(entity),
      argoCDInstance: '',
      argoCDProject: '',
      clusterName: '',
      namespace: '',
      deploymentName: '',
      repoUrl: 'github.com?repo=argocd',
    },
  };

  return (
    <div>
      {linkElementFactory({
        label: 'Run Application',
        icon: AppsIcon,
        onClick: handleToggleModal,
      })}
      <CreateApplicationModal open={showModalCreateApp} toggleModal={handleToggleModal} onSubmit={createApplication.onSubmit} initialState={initialState} />
    </div>
  );
};

export function isReactRouterBeta(): boolean {
  const [obj] = createRoutesFromChildren(<Route index element={<div />} />);
  return !obj.index;
}
