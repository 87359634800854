import { JSONSchema7 } from 'json-schema';

export const BITBUCKET_CONFIG_PLACEHOLDER: string = 'integrations.bitbucketCloud';

export const BITBUCKET: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'Bitbucket' },
    host: { type: 'string', title: 'Host', default: 'server.bitbucket.com', minLength: 1 },
    apiBaseUrl: { type: 'string', title: 'Token', minLength: 1 },
    username: { type: 'string', title: 'Username', minLength: 1 },
    appPassword: {
      type: 'string',
      title: 'App Password',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
  },
  required: ['name', 'host', 'apiBaseUrl', 'username', 'appPassword'],
};
