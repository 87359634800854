import { JSONSchema7 } from 'json-schema';

export const GRAFANA_CONFIG_PLACEHOLDER: string = 'proxy./grafana/api';
export const GRAFANA: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'Grafana' },
    target: { type: 'string', title: 'Target', minLength: 1 },
    headers: {
      type: 'object',
      title: '',
      properties: {
        Authorization: {
          type: 'string',
          title: 'Token',
          minLength: 1,
          description: 'This field contains sensitive information. On edit screen its content will be hidden',
        },
      },
      required: ['Authorization'],
    },
    grafana: {
      type: 'object',
      title: '',
      properties: {
        domain: { type: 'string', title: 'Domain', minLength: 1 },
        unifiedAlerting: { type: 'boolean', title: 'Unified alerting', minLength: 1 },
      },
      required: ['domain', 'unifiedAlerting'],
    },
  },
  required: ['name', 'grafana', 'headers', 'target'],
};
