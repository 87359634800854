import { RecursivePartial } from '@tmatic/utils';
import _ from 'lodash';
import { constants as C } from '../../config';
import { Location } from '../../entities';
import { EntityCreatePayload } from './types';

export class LocationFactory {
  static mergeWithDefaults(payload: RecursivePartial<Location>): Location {
    return _.merge(
      {
        apiVersion: C.BACKSTAGE_IO_V1ALPHA1,
        metadata: { namespace: 'default' },
        spec: {
          system: C.SPEC_DEFAULTS.SYSTEM,
          lifecycle: C.SPEC_DEFAULTS.LIFECYCLE,
        },
      },
      payload,
    ) as Location;
  }

  static createFromPayload(payload: EntityCreatePayload<Location>): Location {
    return LocationFactory.mergeWithDefaults({
      metadata: {
        name: payload.name,
        namespace: payload.namespace || 'default',
        title: payload.title,
        annotations: payload.annotations,
        description: payload.description,
      },
      spec: {
        owner: payload.owner,
        system: payload.system,
        ownedBy: payload.owner,
        partOf: payload.partOf || payload.system,
      },
    });
  }
}
