import { JSONSchema7 } from 'json-schema';

export const DATADOG_CONFIG_PLACEHOLDER: string = 'proxy./datadog/api';

export const DATADOG: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'DataDog' },
    'backend.csp.frame-src': {
      type: 'string',
      title: 'Sources',
      minLength: 1,
      description: 'This field specifies valid sources for nested browsing contexts divided by spaces',
    },
  },
  required: ['name', 'backend.csp.frame-src'],
};
