import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { JSONSchema7 } from 'json-schema';
import { ErrorObject } from 'ajv/lib/types';

// export const RE_K8S_NAME = /^[a-z\d]([-a-z\d]*[a-z\d])?(\.[a-z\d]([-a-z\d]*[a-z\d])?)*$/;
export const RE_K8S_NAME = /^[a-z\d]+(?:-[a-z\d]+)*(?:\.[a-z\d]+(?:-[a-z\d]+)*)*$/;
export const RE_URL = /^http(s)?:\/\/[a-z\d.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/i;
export const RE_HOSTNAME = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\\.(xn--)?([a-z0-9\\-]{1,61}|[a-z0-9-]{1,30}\\.[a-z]{2,})$/i;

const ajv = new Ajv({ allowUnionTypes: true });
addFormats(ajv);

ajv.addFormat('k8s-name', { type: 'string', validate: (x: string) => !!x && RE_K8S_NAME.test(x) });
ajv.addFormat('url', { type: 'string', validate: (x: string) => !!x && RE_URL.test(x) });
ajv.addFormat('hostname', { type: 'string', validate: (x: string) => !!x && RE_HOSTNAME.test(x) });

export const validate = (schema: JSONSchema7, payload: any): null | ErrorObject[] => {
  const validator = ajv.compile(schema);
  validator(payload);
  return validator.errors || null;
};
