import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy } from './BaseEntity.schema';

export const LocationSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind']), {
  properties: {
    kind: { const: C.KIND_LOCATION },
    spec: {
      properties: {
        type: { enum: _.values(C.LOCATION_TYPES) },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
      },
    },
  },
});
