import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';
import { CreateApplicationPayload } from '@tmatic/entity-management-common';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export interface CreateApplication {
  setShowModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onSubmit: (data: CreateApplicationPayload) => Promise<void>;
  showModal: boolean;
}

export const useCreateApplication = (): CreateApplication => {
  const [showModal, setShowModal] = useState(false);
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();

  const onSubmit = async (data: CreateApplicationPayload) => {
    try {
      const { taskId, applicationRef } = await catalogApi.performCustomRequest({
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        url: { suffix: 'argocd/run-application' },
        body: JSON.stringify(data),
      });

      const { name, namespace, kind } = parseEntityRef(applicationRef);
      navigate(`/catalog/${namespace}/${kind}/${name}?task_id=${taskId}`);
    } catch (e) {
      errorApi.post(e);
    }
  };

  return {
    showModal,
    setShowModal,
    onSubmit,
  };
};
