import { KebabToSnakeCase } from '@tmatic/utils';

export const TRANSFORMATIC_IO_V1ALPHA1: 'transformatic.io/v1alpha1' = 'transformatic.io/v1alpha1';
export const BACKSTAGE_IO_V1ALPHA1: 'backstage.io/v1alpha1' = 'backstage.io/v1alpha1';

export const KIND_API: 'API' = 'API';
export const KIND_ARTIFACT: 'Artifact' = 'Artifact';
export const KIND_APPLICATION: 'Application' = 'Application';
export const KIND_COMPONENT: 'Component' = 'Component';
export const KIND_DOMAIN: 'Domain' = 'Domain';
export const KIND_GROUP: 'Group' = 'Group';
export const KIND_LOCATION: 'Location' = 'Location';
export const KIND_RESOURCE: 'Resource' = 'Resource';
export const KIND_SYSTEM: 'System' = 'System';
export const KIND_TEMPLATE: 'Template' = 'Template';
export const KIND_USER: 'User' = 'User';

export type Kind =
  | typeof KIND_API
  | typeof KIND_ARTIFACT
  | typeof KIND_APPLICATION
  | typeof KIND_COMPONENT
  | typeof KIND_DOMAIN
  | typeof KIND_GROUP
  | typeof KIND_LOCATION
  | typeof KIND_RESOURCE
  | typeof KIND_SYSTEM
  | typeof KIND_TEMPLATE
  | typeof KIND_USER;

export type APIType = 'openapi' | 'grpc' | 'graphql' | 'async';
export const API_TYPES: Record<Uppercase<APIType>, APIType> = {
  OPENAPI: 'openapi',
  GRPC: 'grpc',
  ASYNC: 'graphql',
  GRAPHQL: 'async',
};

export type ApplicationType = 'service' | 'url';
export const APPLICATION_TYPES: Record<Uppercase<ApplicationType>, ApplicationType> = {
  SERVICE: 'service',
  URL: 'url',
};

export type ArtifactType = 'docker' | 'npm';
export const ARTIFACT_TYPES: Record<Uppercase<ArtifactType>, ArtifactType> = {
  DOCKER: 'docker',
  NPM: 'npm',
};

export type ComponentType = 'application' | 'backend' | 'code' | 'frontend' | 'library';
export const COMPONENT_TYPES: Record<Uppercase<ComponentType>, ComponentType> = {
  APPLICATION: 'application',
  BACKEND: 'backend',
  CODE: 'code',
  FRONTEND: 'frontend',
  LIBRARY: 'library',
};

export type DomainType = 'domain';
export const DOMAIN_TYPES: Record<Uppercase<DomainType>, DomainType> = {
  DOMAIN: 'domain',
};

export type GroupType = 'group';
export const GROUP_TYPES: Record<Uppercase<GroupType>, GroupType> = {
  GROUP: 'group',
};

export type LocationType = 'url' | 'file';
export const LOCATION_TYPES: Record<Uppercase<LocationType>, LocationType> = {
  URL: 'url',
  FILE: 'file',
};

export type ClusterResourceType = 'k8s-cluster' | 'control-plane';
export type ManagedResource = 'database-server' | 'cloud-storage' | 'queue' | 'computing-instance';
export type AbstractResource = 'database' | 'resource-definition';
export type ResourceType = AbstractResource | ManagedResource | ClusterResourceType | string;
export const RESOURCE_TYPES: Record<Uppercase<KebabToSnakeCase<ResourceType>>, ResourceType> = {
  K8S_CLUSTER: 'k8s-cluster',
  CONTROL_PLANE: 'control-plane',

  DATABASE_SERVER: 'database-server',
  CLOUD_STORAGE: 'cloud-storage',
  QUEUE: 'queue',
  COMPUTING_INSTANCE: 'computing-instance',

  DATABASE: 'database',
  RESOURCE_DEFINITION: 'resource-definition',
};

export type TemplateType = 'service';
export const TEMPLATE_TYPES: Record<Uppercase<TemplateType>, TemplateType> = {
  SERVICE: 'service',
};

export const SPEC_DEFAULTS: {
  LIFECYCLE: string;
  OWNER: string;
  SYSTEM: string;
} = {
  SYSTEM: 'system:default/sdk-generator',
  OWNER: 'groups:default/users',
  LIFECYCLE: 'production',
};
