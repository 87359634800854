export interface ArgoCDLabels {
  EVENTS_ARGO_PROJECT_SENSOR: 'events.argoproj.io/sensor';
  EVENTS_ARGO_PROJECT_TRIGGER: 'events.argoproj.io/trigger';
  TMATIC_ARGO_WF_ENTITY_KIND: 'tmatic.io/argo-workflow-entity-kind';
  TMATIC_ARGO_WF_ENTITY_NAME: 'tmatic.io/argo-workflow-entity-name';
  TMATIC_ARGO_WF_TENANT_ID: 'tmatic.io/argo-workflow-tenant-id';
  TMATIC_ARGO_WF_VISIBLE: 'tmatic.io/argo-workflow-visible';
}

export const ARGO_CD_LABELS: ArgoCDLabels = {
  EVENTS_ARGO_PROJECT_SENSOR: 'events.argoproj.io/sensor',
  EVENTS_ARGO_PROJECT_TRIGGER: 'events.argoproj.io/trigger',
  TMATIC_ARGO_WF_ENTITY_KIND: 'tmatic.io/argo-workflow-entity-kind',
  TMATIC_ARGO_WF_ENTITY_NAME: 'tmatic.io/argo-workflow-entity-name',
  TMATIC_ARGO_WF_TENANT_ID: 'tmatic.io/argo-workflow-tenant-id',
  TMATIC_ARGO_WF_VISIBLE: 'tmatic.io/argo-workflow-visible',
};

export interface TMaticLabels {
  COMPONENT_LANGUAGE: 'component-language';
  COMPONENT_IMPORTANCE: 'component-importance';
}
export const TMATIC_LABELS: TMaticLabels = {
  COMPONENT_LANGUAGE: 'component-language',
  COMPONENT_IMPORTANCE: 'component-importance',
};
