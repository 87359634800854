import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy, jsonObj } from './BaseEntity.schema';

export const TemplateSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind']), {
  type: 'object',
  properties: {
    kind: { const: C.KIND_TEMPLATE },
    spec: {
      type: 'object',
      properties: {
        owner: 'string',
        type: { enum: _.values(C.TEMPLATE_TYPES) },
        parameters: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: { type: 'string' },
              required: {
                type: 'array',
                items: { type: 'string' },
              },
              properties: {
                type: 'object',
                properties: {
                  name: {
                    type: 'object',
                    properties: {
                      title: { type: 'string' },
                      type: { type: 'string' },
                      description: { type: 'string' },
                      'ui:field': { type: 'string' },
                      'ui:autofocus': { type: 'boolean' },
                      'ui:options': {
                        type: 'object',
                        properties: jsonObj('string'),
                      },
                    },
                  },
                },
              },
            },
          },
        },
        steps: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'string' },
              name: { type: 'string' },
              action: { type: 'string' },
              input: {
                type: 'object',
                properties: {
                  url: { type: 'string' },
                  values: { type: 'object' },
                  targetPath: { type: 'string' },
                  description: { type: 'string' },
                  allowedHosts: { type: 'array' },
                },
              },
            },
          },
        },
      },
      required: ['parameters', 'steps'],
    },
  },
});
