import { Progress, Select } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { AddClusterPayload, ResourceK8SClusterConfig } from '@tmatic/configurator-common';
import React, { PropsWithChildren, useState } from 'react';
import MonacoEditor, { EditorDidMount } from 'react-monaco-editor';
import * as monacoEditor from 'monaco-editor';
import seVsDarkTheme from './themes/se-vs-dark';
import seVsLightTheme from './themes/se-vs-light';
import { AddClusterModalProps, TabPanelProps } from './types';
import { validateRequestPayload } from './utils';
import _ from 'lodash';
import { useSpecRequestedSelection } from '../../../hooks/useSpecRequestedSelection';
import { CustomFormLabel } from '../../CustomFormLabel';

const CustomTabPanel = ({ children, value, index, ...rest }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...rest}>
    {value === index && children}
  </div>
);

const MONACO_OPTIONS = {
  selectOnLineNumbers: true,
  scrollBeyondLastLine: false,
  'semanticHighlighting.enabled': true,
  automaticLayout: true,
  minimap: {
    enabled: false,
  },
};

interface GridWrapperProps {
  paddingX?: number;
  paddingY?: number;
  paddingTop?: number;
  paddingYBottom?: number;
  marginX?: number;
  marginY?: number;
  marginTop?: number;
  marginYBottom?: number;
  width?: string;
}

const GridWrapper = ({ children, ...rest }: PropsWithChildren<GridWrapperProps>) => (
  <Grid style={{ backgroundImage: 'none', boxShadow: 'none' }} container component={Paper} {...rest}>
    {children}
  </Grid>
);

// extend payload structure with "description, owner, system"
// -- validation schema
// -- portal form

export const AddClusterModal = (props: AddClusterModalProps) => {
  const { open, hidden, title = 'Add a new Cluster', toggleModal, onSubmit } = props;
  const [form, setForm] = useState<AddClusterPayload<ResourceK8SClusterConfig>>({
    owner: '',
    system: '',
    description: '',
    clusterData: {
      name: '',
      server: '',
      config: {
        bearerToken: '',
        tlsClientConfig: {
          insecure: false,
          caData: '',
        },
      },
    },
  });

  const { groups, systems } = useSpecRequestedSelection();

  const [loading, setLoading] = useState(false);
  const [, setEditor] = useState<monacoEditor.editor.IStandaloneCodeEditor | null>(null);
  const [tab, setTab] = React.useState(0);

  const editorDidMount: EditorDidMount = editorParam => {
    editorParam.focus();
    setEditor(editorParam);
  };

  const editorWillMount = (monaco: typeof monacoEditor) => {
    monaco.editor.defineTheme('se-vc-dark', seVsDarkTheme as monacoEditor.editor.IStandaloneThemeData);
    monaco.editor.defineTheme('se-vc-light', seVsLightTheme as monacoEditor.editor.IStandaloneThemeData);
  };

  const clusterDataInputs = (
    <>
      <CustomTabPanel value={tab} index={0}>
        <GridWrapper>
          <Grid item xs={12} marginTop={2}>
            <TextField
              label={<CustomFormLabel text="Cluster Name *" />}
              placeholder="Cluster Name"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.clusterData?.name}
              onChange={e =>
                setForm({
                  ...form,
                  clusterData: {
                    ...form.clusterData,
                    name: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <TextField
              label={<CustomFormLabel text="Cluster URL *" />}
              placeholder="Cluster URL *"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.clusterData?.server}
              onChange={e =>
                setForm({
                  ...form,
                  clusterData: {
                    ...form.clusterData,
                    server: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <TextField
              label={<CustomFormLabel text="Bearer token *" />}
              placeholder="Bearer"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.clusterData?.config?.bearerToken}
              onChange={e =>
                setForm({
                  ...form,
                  clusterData: {
                    ...form.clusterData,
                    config: {
                      ...form.clusterData?.config,
                      bearerToken: e.target.value,
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} marginTop={1} marginBottom={2}>
            <TextField
              label={<CustomFormLabel text="CA Data *" />}
              placeholder="ca data"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.clusterData?.config?.tlsClientConfig?.caData || ''}
              onChange={e =>
                setForm({
                  ...form,
                  clusterData: {
                    ...form.clusterData,
                    config: {
                      ...form.clusterData?.config,
                      tlsClientConfig: {
                        insecure: false,
                        caData: e.target.value,
                      },
                    },
                  },
                })
              }
            />
          </Grid>
        </GridWrapper>
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <GridWrapper marginTop={1} width="758px">
          <Grid item xs={12} marginTop={2} paddingX={2}>
            <MonacoEditor
              height="488px"
              language="json"
              theme="se-vc-light"
              value={`${JSON.stringify(form.clusterData, null, 2)}\n`}
              options={MONACO_OPTIONS}
              onChange={value => {
                try {
                  setForm({
                    ...form,
                    clusterData: JSON.parse(value),
                  });
                } catch (error) {
                  // eslint-disable-next-line no-empty
                }
                // todo
                // ugly solution. monaco-editor does not have onBlur subscription
                // errorApi.post(error); // on blur post error message
              }}
              editorDidMount={editorDidMount}
              editorWillMount={editorWillMount}
            />
          </Grid>
        </GridWrapper>
      </CustomTabPanel>
    </>
  );

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open} hidden={hidden}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <GridWrapper>
          <Grid item xs={12} marginTop={2}>
            <TextField
              label={<CustomFormLabel text="Description" />}
              placeholder="Description"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.description}
              onChange={e => setForm({ ...form, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="Owner *" pull="right" />) as unknown as string}
              items={groups.value || []}
              onChange={value => setForm({ ...form, owner: _.toString(value) })}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="System *" pull="right" />) as unknown as string}
              items={systems.value || []}
              onChange={value => setForm({ ...form, system: _.toString(value) })}
            />
          </Grid>
        </GridWrapper>
        <GridWrapper marginTop={2} paddingX={2}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel control={<Switch checked={!!tab} onChange={() => setTab(+!tab)} />} label={!tab ? 'JSON' : 'Form'} labelPlacement="end" />
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
        </GridWrapper>
        {clusterDataInputs}
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <div style={{ width: '100%', textAlign: 'right', marginTop: '1em' }}>
          <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
            Close
          </Button>
          <Button
            style={{ marginLeft: '5px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              onSubmit(form).then(() => setLoading(false));
            }}
            disabled={!!_.size(validateRequestPayload(form)) || loading}
          >
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
