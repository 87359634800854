import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import _ from 'lodash';
import jsYaml from 'js-yaml';
import url from 'url';
import { FlattenArrayValues, SIZE } from './types';

export const objectToKeyValuePairs = (record: Record<string, string | number | null | boolean | undefined>) => _.map(record, (value, key) => `${key}=${value}`);

export type LoggerContextBuilder = (httpRequest: any, context?: any) => Record<string, any>;
export const createLoggerContextBuilder = (plugin: string, meta: Record<string, any> = {}): LoggerContextBuilder => {
  return (httpRequest: any, context: any = {}) => ({
    request_id: httpRequest.id,
    plugin,
    ...meta,
    ...context,
  });
};

export const safeJsonParse = <T extends Record<string, any> = Record<string, any>, D extends any = any>(str: string, defaultValue: any = null): T | D => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return defaultValue;
  }
};

export const safeYamlLoad = <T extends Record<string, any> = Record<string, any>, D extends any = any>(str: string, defaultValue: any = null): T | D => {
  try {
    return jsYaml.load(str) as T;
  } catch (err) {
    return defaultValue;
  }
};

export const parseScaffolderFormattedRepositoryUrl = (
  repoUrl: string,
): {
  host?: string;
  owner?: string;
  repo?: string;
  organization?: string;
  workspace?: string;
  project?: string;
} => {
  let host;
  let owner;
  let organization;
  let workspace;
  let project;
  let repo;
  try {
    const parsed = new URL(`https://${repoUrl}`);
    host = parsed.host;
    repo = parsed.searchParams.get('repo') || '';
    owner = parsed.searchParams.get('owner') || '';
    organization = parsed.searchParams.get('organization') || '';
    workspace = parsed.searchParams.get('workspace') || '';
    project = parsed.searchParams.get('project') || '';
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return { host, owner, repo, organization, workspace, project };
};

export const SIZES: { [key in SIZE]: number } = {
  Kb: Math.pow(2, 10),
  Mb: Math.pow(2, 20),
  Gb: Math.pow(2, 30),
};

export const choreSize = (size: number, fractionDigits = 1): string => {
  switch (true) {
    case size < SIZES.Kb:
      return `${size} Bytes`;
    case size < SIZES.Mb:
      return `${(size / SIZES.Kb).toFixed(fractionDigits)} Kb`;
    case size < SIZES.Gb:
      return `${(size / SIZES.Mb).toFixed(fractionDigits)} Mb`;
    default:
      return `${(size / SIZES.Gb).toFixed(fractionDigits)} Gb`;
  }
};

export const tOut = (t = 500) => new Promise(resolve => setTimeout(resolve, t));

export const promiseWithRetries = async <T extends any = any>(asyncFn: () => Promise<T>, retries = 3, timeout = 0): Promise<T> => {
  let p = asyncFn();
  for (let i = 0; i < retries; i++) {
    p = p.catch(() => tOut(timeout).then(asyncFn));
  }
  return p;
};

export const mapRecordsValues = <T extends Record<string, string | string[]> = Record<string, string | string[]>>(records: any[], predicate: T): FlattenArrayValues<T>[] =>
  _.map(records, record => _.mapValues(predicate, pointer => _.get(record, pointer, '')));

const RE_ANY_CASE = /[a-z]/i;
const RE_UPPER_CASE = /[A-Z]/;
const RE_LOWER_CASE = /[a-z]/;
const RE_DIGITS = /[0-9]/;

export const upperCamelToKebab = (input: string): string => {
  let result = '';
  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    const prevChar = input[i - 1];
    const nextChar = input[i + 1];

    switch (true) {
      case RE_UPPER_CASE.test(char): {
        if (prevChar !== undefined && nextChar !== undefined) {
          if (RE_LOWER_CASE.test(prevChar) && RE_LOWER_CASE.test(nextChar)) {
            result += '-';
          } else if (RE_UPPER_CASE.test(prevChar) && RE_LOWER_CASE.test(nextChar)) {
            result += '-';
          }
        }
        result += char.toLocaleLowerCase('en-US');
        break;
      }
      case RE_DIGITS.test(char): {
        result += char;
        if (RE_ANY_CASE.test(nextChar) && i !== input.length - 1) {
          result += '-';
        }
        break;
      }
      default: {
        result += char;
      }
    }
  }

  return result;
};

// console.log(upperCamelToKebab("MENSA")); // Output: mensa
// console.log(upperCamelToKebab("AWSConsole")); // Output: aws-console
// console.log(upperCamelToKebab("S3Bucket")); // Output: s3-bucket
// console.log(upperCamelToKebab("S33Bucket")); // Output: s33-bucket
// console.log(upperCamelToKebab("EC2")); // Output: ec2
// console.log(upperCamelToKebab("EC2-Test")); // Output: ec2-test
// console.log(upperCamelToKebab("UpperCamelCase")); // Output: upper-camel-case

type SortOrder = 'asc' | 'desc';
// todo: cover with tests
export const sortByProperties = <T>(data: T[], properties: Array<keyof T>, order: SortOrder = 'asc'): T[] => {
  return data.sort((a, b) => {
    for (const prop of properties) {
      const aValue = a[prop];
      const bValue = b[prop];

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });
};

export const base64ToUtf8 = (str: string): string => Buffer.from(str, 'base64').toString('utf8');

export const utf8toBase64 = (str: string): string => Buffer.from(str, 'utf8').toString('base64');

export const normalizeEntityRef = (ref: string, defaults?: { defaultKind?: string; defaultNamespace?: string }) => stringifyEntityRef(parseEntityRef(ref, defaults));

export const parseUrl = (input: string) => {
  const { host, pathname } = url.parse(input);
  return {
    host: host || '',
    pathname: pathname || '',
  };
};

// export const scaffolderLinkToUrl = () => {
//
// }

export const urlToScaffolderLink = (input: string): string => {
  const { host, pathname } = parseUrl(input);
  let ownerName: string;
  let repoName: string;

  switch (host) {
    case 'github.com': {
      const [organization, repository] = pathname.split('/').filter(Boolean);
      ownerName = organization;
      repoName = repository;
      break;
    }
    default: {
      ownerName = '';
      repoName = '';
    }
  }

  return `${host}?repo=${repoName}&owner=${ownerName}`;
};
