import React, { CSSProperties, useCallback, useReducer, useState } from 'react';
import { DynamicContentPickerProps } from './schema';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { formInputStyles } from './__styles';
import { initialState, reducer, STATE_FORMAT } from './state';
import { Action, Format, State } from './types';

export { DynamicContentPickerSchema } from './schema';

const MIN_ROWS_SIZE = 10;
const MAX_ROWS_SIZE = 30;

/**
 * Dynamic Content Picker
 */
export const DynamicContentPicker = (props: DynamicContentPickerProps) => {
  const {
    onChange,
    required,
    // @ts-ignore
    schema: { title = 'Content', accept = '.json,.yaml' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  // initial state is going to be the formData
  // ts glitch, useReducer third argument is optional, but being highlighted as required
  // @ts-ignore
  const [state, dispatch]: [State, (action?: Action) => void] = useReducer(reducer, initialState);
  const [fileName, setFileName] = useState('');
  const getDefinitionInput = useCallback(() => {
    const setDefinition = (value: string) => {
      dispatch({ type: 'setValue', value });
      onChange(value as string);
    };
    switch (state.format) {
      case STATE_FORMAT.FILE: {
        return (
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <input
              id="file-input"
              accept={accept}
              onChange={event => {
                const file = event.target?.files?.[0];
                if (file) {
                  setFileName(file.name);
                  const reader = new FileReader();
                  reader.onload = () => setDefinition(new TextDecoder('utf-8').decode(reader.result as ArrayBuffer));
                  reader.readAsArrayBuffer(file);
                }
              }}
              required={required}
              style={formInputStyles.input}
              type="file"
            />
            <label htmlFor="file-input" style={formInputStyles.button as CSSProperties}>
              {fileName ? `Chosen: ${fileName}` : 'Choose File'}
            </label>
          </FormControl>
        );
      }
      case STATE_FORMAT.URL: {
        return (
          <FormControl fullWidth>
            <TextField
              error={rawErrors?.length > 0 && !formData}
              helperText="Insert a valid URL"
              id={(idSchema?.$id || 'url-input') as string}
              inputProps={{ autoFocus }}
              label={title}
              margin="normal"
              onChange={event => setDefinition(event.target.value)}
              placeholder={placeholder as string}
              required={required}
              value={state.value}
              variant="outlined"
            />
          </FormControl>
        );
      }
      case STATE_FORMAT.TEXT: {
        const currentSize = String(state.value).split('\n').length;
        const maxRowsForTernary = currentSize > MAX_ROWS_SIZE ? MAX_ROWS_SIZE : currentSize;
        const minRows = currentSize < MIN_ROWS_SIZE ? MIN_ROWS_SIZE : maxRowsForTernary;
        return (
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              error={rawErrors?.length > 0 && !formData}
              helperText="Insert API definition in YAML format"
              id={(idSchema?.$id || 'text-input') as string}
              inputProps={{ autoFocus }}
              label={title}
              margin="normal"
              maxRows={MAX_ROWS_SIZE}
              minRows={minRows}
              multiline
              onChange={event => setDefinition(event.target.value)}
              placeholder={placeholder as string}
              required={required}
              value={state.value}
            />
          </FormControl>
        );
      }
      default: {
        return null;
      }
    }
  }, [state.format, state.value, autoFocus, fileName, formData, idSchema?.$id, placeholder, rawErrors?.length, required, onChange, title, accept]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="apiVersion-label">Input format</InputLabel>
            <Select
              labelId="apiVersion-label"
              value={state.format}
              onChange={event => {
                setFileName('');
                dispatch({
                  type: 'merge',
                  value: { format: event.target.value as Format, value: '' },
                });
              }}
            >
              {Object.values(STATE_FORMAT).map((format, index) => (
                <MenuItem key={index} value={format as Format}>
                  {format.toLocaleUpperCase('en-US')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {getDefinitionInput()}
        </Grid>
      </Grid>
    </>
  );
};
