import { JSONSchema7 } from 'json-schema';
import { DiscoveryType } from './types';

export const DOCKER_REGISTRY_CONFIG_PLACEHOLDER: string = 'registries.docker';

export const DOCKER_REGISTRY_TYPE: DiscoveryType = 'docker';

export const DOCKER_REGISTRY: JSONSchema7 = {
  type: 'object',
  properties: {
    uid: { type: 'string', minLength: 36, maxLength: 36 },
    type: { type: 'string', enum: [DOCKER_REGISTRY_TYPE], default: DOCKER_REGISTRY_TYPE },
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'Docker Registry' },
    credentialsUid: { title: 'Credentials', type: 'string', minLength: 1, maxLength: 64 },
    filter: {
      title: 'Filter patterns (any of)',
      type: 'array',
      items: { type: 'string', title: '', minLength: 1, maxLength: 64 },
    },
  },
  required: ['name', 'type', 'credentialsUid'],
};
