import { DEFAULT_NAMESPACE, Entity, parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { ApiRef, AppContext, RouteFunc, useApi, useApp, useRouteRef } from '@backstage/core-plugin-api';
import { ScmIntegrationIcon, scmIntegrationsApiRef } from '@backstage/integration-react';
import { EntitySourceLocation, getEntitySourceLocation, useEntity } from '@backstage/plugin-catalog-react';
import { ScmIntegrationRegistry } from '@backstage/integration';
import { TMATIC_ANNOTATION, TMATIC_ANNOTATION_LINK_APPLICATION, TMATIC_ANNOTATION_LINK_CR, TMATIC_ANNOTATION_LINK_ECR, TMATIC_ANNOTATION_LINK_NPM } from '@tmatic/constants-common';
import { Resource } from '@tmatic/entity-management-common';
import ControlPlane from '@mui/icons-material/ControlCamera';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import DocsIcon from '@mui/icons-material/Description';
import PagesOutlined from '@mui/icons-material/PagesOutlined';
import WebIcon from '@mui/icons-material/Web';
import Dns from '@mui/icons-material/Dns';
import Publish from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';
import { linkElementFactory, linkModalCreateApp } from './LinkTMatic';
import { createFromTemplateRouteRef, viewTechDocRouteRef } from '../../routes';
import { extractAnnotation, extractRepositoryInfo, pickAttributes } from './utils';
import { CreateApplication, useCreateApplication } from '../../hooks';
import React, { useState } from 'react';

const getSubHeaderLinksSource = (props: { entitySourceLocation?: EntitySourceLocation }): JSX.Element[] => {
  const { entitySourceLocation } = props;
  return [
    linkElementFactory({
      label: 'View Source',
      disabled: !entitySourceLocation,
      icon: ScmIntegrationIcon,
      iconArgs: { type: entitySourceLocation?.integrationType },
      href: entitySourceLocation?.locationTargetUrl,
    }),
  ];
};

const getSubHeaderLinksTechDocs = (props: { entity: Entity; viewTechdocLink?: RouteFunc<{ namespace: string; kind: string; name: string }> }): JSX.Element[] => {
  const { entity, viewTechdocLink } = props;
  return [
    linkElementFactory({
      label: 'View TechDocs',
      disabled: !entity.metadata.annotations?.['backstage.io/techdocs-ref'] || !viewTechdocLink,
      icon: DocsIcon,
      href:
        viewTechdocLink &&
        viewTechdocLink({
          namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
          kind: entity.kind,
          name: entity.metadata.name,
        }),
      target: '_blank',
    }),
  ];
};

const getSubHeaderLinksLaunchTemplate = (props: { entity: Entity; templateRoute?: RouteFunc<{ namespace: string; templateName: string }>; app: AppContext }): JSX.Element[] => {
  const { entity, templateRoute, app } = props;
  const Icon = app.getSystemIcon('scaffolder') ?? CreateComponentIcon;

  return [
    linkElementFactory({
      label: 'Launch Template',
      icon: Icon,
      disabled: !templateRoute,
      href:
        templateRoute &&
        templateRoute({
          templateName: entity.metadata.name,
          namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
        }),
      target: '_blank',
    }),
  ];
};

const getSubHeaderLinksApplication = ({ entity }: { entity: Entity }): JSX.Element[] => {
  const links: JSX.Element[] = [];
  const appLink = extractAnnotation(entity, TMATIC_ANNOTATION_LINK_APPLICATION)?.replace(/^url:/, '');
  if (appLink) {
    links.push(
      linkElementFactory({
        label: 'View Application',
        icon: WebIcon,
        href: appLink,
        target: '_blank',
      }),
    );
  }
  return links;
};

const getSubHeaderLinksComponent = (props: { entity: Entity; templateRoute?: RouteFunc<{ namespace: string; templateName: string }> }): JSX.Element[] => {
  const { entity, templateRoute } = props;
  if (entity.spec?.type === 'library') {
    return [
      linkElementFactory({
        label: 'NPM Publish',
        icon: Publish,
        disabled: !templateRoute,
        href: `/create/templates/default/component-publish-npm?formData=${encodeURIComponent(
          JSON.stringify({
            ...pickAttributes(entity),
            ...extractRepositoryInfo(entity),
          }),
        )}`,
        target: '_blank',
      }),
    ];
  }
  return [];
};

const getSubHeaderLinksBuildImage = (props: { entity: Entity; templateRoute?: RouteFunc<{ namespace: string; templateName: string }> }): JSX.Element[] => {
  const { entity } = props;
  if (_.includes(['backend', 'frontend'], entity.spec?.type)) {
    return [
      linkElementFactory({
        label: 'Build Image',
        icon: Publish,
        href: `/create/templates/default/build-image?formData=${encodeURIComponent(
          JSON.stringify({
            repositoryName: entity.metadata.name, // todo dockerRepositoryName
            ...pickAttributes(entity),
            ...extractRepositoryInfo(entity),
          }),
        )}`,
        target: '_blank',
      }),
    ];
  }
  return [];
};

const getSubHeaderLinksOpenNpm = (props: { entity: Entity }): JSX.Element[] => {
  const { entity } = props;

  const npmLink = extractAnnotation(entity, TMATIC_ANNOTATION_LINK_NPM);
  if (npmLink) {
    return [
      linkElementFactory({
        label: 'Open NPM',
        icon: PagesOutlined,
        href: npmLink,
        target: '_blank',
      }),
    ];
  }
  return [];
};

const getSubHeaderLinksViewDockerImage = (props: { entity: Entity }) => {
  const { entity } = props;
  const crLink = extractAnnotation(entity, TMATIC_ANNOTATION_LINK_CR) || extractAnnotation(entity, TMATIC_ANNOTATION_LINK_ECR);
  if (crLink) {
    return [
      linkElementFactory({
        label: 'View Docker Image',
        icon: Dns,
        href: crLink,
        target: '_blank',
      }),
    ];
  }
  return [];
};

const getSubHeaderLinksResource = ({ entity }: { entity: Resource }): JSX.Element[] => {
  const links: JSX.Element[] = [];
  const controlPlaneRef = entity.metadata.annotations?.[TMATIC_ANNOTATION.CONTROL_PLANE_ENTITY_REF];
  if (_.isString(controlPlaneRef)) {
    const cpRef = parseEntityRef(controlPlaneRef);
    links.push(
      linkElementFactory({
        label: 'Control Plane',
        icon: ControlPlane,
        href: `/catalog/${cpRef.namespace}/${cpRef.kind}/${cpRef.name}`,
        target: '_blank',
      }),
    );
  }

  return links;
};

const getSubHeaderLinksCreateBackendService = (props: { entity: Entity }): JSX.Element[] => {
  const { entity } = props;

  const formData = {
    apiConsumedByComponent: stringifyEntityRef(entity),
    componentName: `${entity.metadata.name}-nodejs-component`,
    gitRepositoryName: `${entity.metadata.name}-nodejs-component`,
  };

  return [
    linkElementFactory({
      label: 'Create Backend Service',
      icon: Publish,
      disabled: false,
      href: `/create/templates/${entity.metadata.namespace}/generate-component-openapi-nodejs-github?formData=${encodeURIComponent(JSON.stringify(formData))}`,
      target: '_blank',
    }),
  ];
};

const getSubHeaderLinksCreateFrontendSDK = (props: { entity: Entity }): JSX.Element[] => {
  const { entity } = props;

  const formData = {
    apiConsumedByComponent: stringifyEntityRef(entity),
    componentName: `${entity.metadata.name}-typescript-component`,
    gitRepositoryName: `${entity.metadata.name}-typescript-component`,
  };

  return [
    linkElementFactory({
      label: 'Create Frontend SDK',
      icon: Publish,
      disabled: false,
      href: `/create/templates/${entity.metadata.namespace}/generate-component-openapi-typescript-github?formData=${encodeURIComponent(JSON.stringify(formData))}`,
      target: '_blank',
    }),
  ];
};

// @ts-ignore
const getSubHeaderLinksOpenLink = (props: { entity: Entity; navigate: any }): JSX.Element[] => {
  const { entity, navigate } = props;

  return [
    linkElementFactory({
      label: 'Edit',
      icon: EditIcon,
      disabled: false,
      onClick: () => navigate(`/catalog/${entity.metadata.namespace}/api/${entity.metadata.name}/editor`),
    }),
  ];
};

const getSubHeaderLinksRunApplication = (props: {
  entity: Entity;
  createApplication: CreateApplication;
  showModalCreateApp: boolean;
  setShowModalCreateApp: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element[] => {
  const { entity, createApplication, showModalCreateApp, setShowModalCreateApp } = props;
  if (entity.spec?.type?.toString().toLocaleLowerCase('en-US') === 'docker') {
    return [linkModalCreateApp({ entity, createApplication, showModalCreateApp, setShowModalCreateApp })];
  }
  return [];
};

export const useSubHeaderLinks = () => {
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi<ScmIntegrationRegistry>(scmIntegrationsApiRef as unknown as ApiRef<ScmIntegrationRegistry>);
  const entitySourceLocation = getEntitySourceLocation(entity, scmIntegrationsApi);
  const viewTechdocLink = useRouteRef(viewTechDocRouteRef);
  const templateRoute = useRouteRef(createFromTemplateRouteRef);
  const app = useApp();
  const createApplication = useCreateApplication();
  const [showModalCreateApp, setShowModalCreateApp] = useState(false);

  const links: JSX.Element[] = [];
  switch (entity.kind.toLocaleLowerCase('en-US')) {
    case 'artifact': {
      links.push(...getSubHeaderLinksRunApplication({ entity, createApplication, showModalCreateApp: showModalCreateApp, setShowModalCreateApp: setShowModalCreateApp }));
      links.push(...getSubHeaderLinksOpenNpm({ entity }));
      // TMATIC_ANNOTATION_LINK_ECR is deprecated
      links.push(...getSubHeaderLinksViewDockerImage({ entity }));
      break;
    }
    case 'application': {
      links.push(...getSubHeaderLinksApplication({ entity }));
      break;
    }
    case 'component': {
      links.push(...getSubHeaderLinksSource({ entitySourceLocation }));
      links.push(...getSubHeaderLinksComponent({ entity, templateRoute }));
      links.push(...getSubHeaderLinksBuildImage({ entity, templateRoute }));
      break;
    }
    case 'template': {
      links.push(...getSubHeaderLinksSource({ entitySourceLocation }));
      links.push(...getSubHeaderLinksTechDocs({ entity, viewTechdocLink }));
      links.push(...getSubHeaderLinksLaunchTemplate({ entity, templateRoute, app }));
      break;
    }
    case 'resource': {
      links.push(...getSubHeaderLinksResource({ entity: entity as Resource }));
      break;
    }
    case 'api': {
      links.push(...getSubHeaderLinksCreateBackendService({ entity }));
      links.push(...getSubHeaderLinksCreateFrontendSDK({ entity }));
      break;
    }
    default: {
      /* empty */
    }
  }
  return links;
};
