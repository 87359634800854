import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy } from './BaseEntity.schema';

export const DomainSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind']), {
  properties: {
    kind: { const: C.KIND_DOMAIN },
    spec: {
      properties: {
        type: { enum: _.values(C.DOMAIN_TYPES) },
      },
    },
  },
});
