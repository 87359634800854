import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeSnippet, InfoCard, Progress, WarningPanel } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import axios from 'axios';
import _ from 'lodash';
import { columns, TableRecord } from './utils';
import { CardTMatic } from '../CardTMatic';

interface ComponentState {
  loading: boolean;
  value: any | null;
  error: Error | null;
}

interface FetchRepositoryInfoOptions {
  url: string;
  controller: AbortController;
}

const fetchRepositoryInfo = ({ url, controller }: FetchRepositoryInfoOptions) => {
  return axios
    .get(url, { signal: controller.signal })
    .then(({ data }) => ({
      value: data,
      loading: false,
    }))
    .catch(() => ({
      loading: false,
      error: new Error('Error fetching Repository Info or Package Not Found'),
      value: null,
    }));
};

export const DockerRepositoryInfoCard = () => {
  const variant = 'gridItem';
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const backendUrl = config.get('backend.baseUrl');
  const cardTitle = 'Images';

  const [state, setState] = useState<ComponentState>({
    loading: false,
    value: null,
    error: null,
  });
  const { value: images, loading, error } = state;

  const controller = useMemo(() => new AbortController(), []);
  const metadata = useMemo(() => entity.metadata, [entity]);

  const fetchMetadata = useCallback(() => {
    const mergeState = (props: Partial<ComponentState>) => setState({ ...state, ...props });

    mergeState({ loading: true });
    if (metadata.name) {
      const { name, namespace = 'default' } = metadata;
      const url = `${backendUrl}/api/cloud-providers/artifacts/${namespace}/${name}/repository-images`;
      fetchRepositoryInfo({ url, controller }).then(mergeState);
    }
  }, [state, setState, controller, backendUrl, metadata]);

  useEffect(() => {
    fetchMetadata();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller]);

  const refreshEntity = useCallback(fetchMetadata, [fetchMetadata]);

  if (loading) {
    return (
      <InfoCard variant={variant} title="Repository Info">
        <Progress />
      </InfoCard>
    );
  }

  if (error || !_.size(images)) {
    let title: string;
    let severity: 'error' | 'info';
    let message: React.ReactNode;

    if (!_.size(images)) {
      title = 'There are no images yet';
      severity = 'info';
    } else {
      title = 'Could not load Repository Info';
      severity = 'error';
      message = <CodeSnippet text={`${error}`} language="text" />;
    }

    return (
      <InfoCard variant={variant} title="Repository Info">
        <WarningPanel severity={severity} title={title} message={message} />
      </InfoCard>
    );
  }

  const actions = (
    <IconButton aria-label="Refresh" title="Refresh Repository Info" onClick={refreshEntity} size="large">
      <CachedIcon />
    </IconButton>
  );

  return <CardTMatic<TableRecord> data={images} cardTitle={cardTitle} columns={columns} action={actions} />;
};

/** @public */
export const isPluginApplicableToEntity = (entity: Entity) => entity.spec?.type === 'docker';
