import { ArrayFieldTemplateProps } from '@rjsf/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useStyles } from './common';

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const classes = useStyles();
  const isAllowed = false;

  return (
    <div className={classes.root}>
      <Typography>{props.title}</Typography>
      {props.items &&
        props.items.map((element, index) => {
          return (
            <AccordionDetails key={index} className={classes.item}>
              <div className={classes.childrenItem}>{element.children}</div>
              <div className={classes.childrenActions}>
                {element.hasRemove && (
                  <IconButton onClick={element.onDropIndexClick(element.index)} size="large">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
                {element.hasMoveDown && isAllowed && (
                  <IconButton onClick={element.onReorderClick(element.index, element.index + 1)} size="large">
                    <ArrowDropDownIcon fontSize="small" />
                  </IconButton>
                )}
                {element.hasMoveUp && isAllowed && (
                  <IconButton onClick={element.onReorderClick(element.index, element.index - 1)} size="large">
                    <ArrowDropUpIcon fontSize="small" />
                  </IconButton>
                )}
                {element.hasCopy && isAllowed && (
                  <IconButton onClick={element.onCopyIndexClick(element.index)} size="large">
                    copy
                  </IconButton>
                )}
              </div>
            </AccordionDetails>
          );
        })}
      {props.canAdd && (
        <Button size="small" variant="outlined" color="secondary" onClick={props.onAddClick}>
          Add element
        </Button>
      )}
    </div>
  );
}
