import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Progress } from '@backstage/core-components';

export interface ModalContainerProps {
  title: string;
  toggleModal: () => void;
  open?: boolean;
  loading?: boolean;
  actions?: React.ReactNode;
}

export const ModalContainer = (props: PropsWithChildren<ModalContainerProps>) => {
  const { open, loading, title, toggleModal, children, actions } = props;

  return (
    <Dialog onClose={toggleModal} open={!!open}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>{children}</Box>
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
