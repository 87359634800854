import { Select } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { validate } from '@tmatic/configurator-common';
import { CreateAPIPayload } from '@tmatic/entity-management-common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styled from '@mui/styles/styled';
import FormLabel from '@mui/material/FormLabel';
import { Progress } from '@backstage/core-components';
import ToggleButton from '@mui/material/ToggleButton';
import React, { useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { CustomFormLabel } from '../../CustomFormLabel';
import { FormErrorMessage } from '../../FormErrorMessage';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileButton = {
  display: 'inline-flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: 'transparent',
  outline: '0px',
  margin: '0px',
  marginTop: '11px !important',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: '500 !important',
  fontSize: '0.9375rem',
  lineHeight: '1.75',
  letterSpacing: '0.02857em',
  textTransform: 'uppercase !important',
  minWidth: '64px !important',
  padding: '6px 22px !important',
  borderRadius: '4px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '1px solid #39D98A !important',
  color: '#39D98A !important',
  width: '100%',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export interface CreateAPIModalProps {
  open?: boolean;
  hidden?: boolean;
  toggleModal: () => void;
  onSubmit: (data: CreateAPIPayload) => Promise<void>;
}

const formSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', minLength: 1, maxLength: 255, format: 'k8s-name' },
    description: { type: 'string', maxLength: 1022 },
    owner: { type: 'string', minLength: 1, maxLength: 255 },
    system: { type: 'string', minLength: 1, maxLength: 255 },
  },
  required: ['owner', 'system'],
};

const urlSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    url: { type: 'string', format: 'url' },
    owner: { type: 'string', minLength: 1, maxLength: 255 },
    system: { type: 'string', minLength: 1, maxLength: 255 },
  },
};

const INITIAL_STATE: CreateAPIPayload = {
  version: '3.0',
  format: 'yaml',
};

export const CreateAPIModal = (props: CreateAPIModalProps) => {
  const { open, hidden, toggleModal, onSubmit } = props;
  const wasChanged = useMemo(() => new Set<string>(), []);

  const catalogApi = useApi(catalogApiRef);
  const [form, setForm] = useState<CreateAPIPayload>(INITIAL_STATE);
  const [fileName, setFileName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const createOnChange = (propName: string): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> => {
    return (e): void => {
      wasChanged.add(propName);
      setForm({ ...form, [propName]: e.target.value });
    };
  };

  const groups = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'Group' } });
    return _.map(result.items, ({ metadata: { name } }) => ({ label: name, value: name }));
  }, []);

  const systems = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'System' } });
    return _.map(result.items, ({ metadata: { name } }) => ({ label: name, value: name }));
  }, []);

  const isLoading = loading || groups.loading || systems.loading;

  const bySpec = !!form.spec;
  const byUrl = !!form.url;

  const getValidationErrors = () => {
    // for uploaded specification we expect valid yaml document
    if (bySpec) {
      return null;
    }

    // if url field is not empty - we validate url field, if empty - considering the form content
    return validate(byUrl ? urlSchema : formSchema, form);
  };

  const errors = getValidationErrors();

  const isSubmitDisabled = (): boolean => {
    if (loading) {
      return true;
    }

    if (bySpec) {
      return false;
    }

    return !!_.size(errors);
  };

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open} hidden={hidden}>
      {isLoading && <Progress />}
      <DialogTitle>Create a new API</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={!!form.url}
              label={<CustomFormLabel text="API Name" comment="(ignored, if specification uploaded from file or url)" />}
              placeholder="API Name"
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={createOnChange('name')}
            />
            <FormErrorMessage errors={errors} wasChanged={wasChanged} property="name" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<CustomFormLabel text="API Description" />}
              placeholder="API Description"
              margin="normal"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              onChange={createOnChange('description')}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="Owner *" pull="right" />) as unknown as string}
              items={groups.value || []}
              onChange={value => setForm({ ...form, owner: _.toString(value) })}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="System *" pull="right" />) as unknown as string}
              items={systems.value || []}
              onChange={value => setForm({ ...form, system: _.toString(value) })}
            />
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <Box style={{ paddingBottom: '1rem', width: '100%' }}>
              <FormLabel>Version</FormLabel>
            </Box>
            <ToggleButtonGroup
              color="primary"
              value={form.version}
              exclusive
              onChange={(_1, version) => {
                setForm({ ...form, version: version as CreateAPIPayload['version'] });
              }}
              aria-label="spec-version-radio"
            >
              <ToggleButton value="3.0">3.0</ToggleButton>
              <ToggleButton value="3.1">3.1</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <Box style={{ paddingBottom: '1rem', width: '100%' }}>
              <FormLabel>Format</FormLabel>
            </Box>
            <ToggleButtonGroup color="primary" value={form.format} exclusive aria-label="spec-format-radio">
              <ToggleButton value="yaml">YAML</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} marginTop={2} marginBottom={1}>
            <Divider textAlign="center">
              <Typography>OR</Typography>
            </Divider>
          </Grid>
          <Grid item xs={5} marginTop={2} paddingTop={1}>
            <Button component="label" variant="outlined" sx={FileButton}>
              {fileName || 'Upload Specification File'}
              <VisuallyHiddenInput
                id="file-upload"
                type="file"
                accept=".yaml, .yml"
                onChange={event => {
                  const file = event.target?.files?.[0];
                  if (file) {
                    setFileName(file.name);
                    const reader = new FileReader();
                    reader.onload = () => {
                      setForm({
                        ...form,
                        spec: new TextDecoder('utf-8').decode(reader.result as ArrayBuffer),
                      });
                    };
                    reader.readAsArrayBuffer(file);
                  }
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={2} marginTop={2}>
            <Typography style={{ marginTop: '1rem' }} align="center">
              OR
            </Typography>
          </Grid>
          <Grid item xs={5} marginTop={2}>
            <TextField style={{ minHeight: '40px' }} placeholder="Insert API Specification URL" margin="normal" variant="outlined" onChange={createOnChange('url')} fullWidth />
            <FormErrorMessage errors={errors} wasChanged={wasChanged} property="url" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            onSubmit(form).finally(() => setLoading(false));
          }}
          disabled={isSubmitDisabled()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
