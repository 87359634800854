import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { CreateAPIPayload } from '@tmatic/entity-management-common';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useState } from 'react';
import jsYaml from 'js-yaml';
import { awaitExecutionFinish } from '../utils';

const defaultTemplate = (version: '3.0' | '3.1'): string =>
  `
openapi: ${version}.0
info:
  title: Hello TMatic API
  version: 1.0.0
  description: A simple Hello TMatic API (OpenAPI Spec)
  contact:
    name: Support
    email: support@techblueprint.io
    url: https://techblueprint.io/support
servers:
  - url: https://techblueprint.io
    description: Example server
tags:
  - name: hello
paths:
  /hello:
    get:
      operationId: helloMessage
      tags:
        - hello
      summary: Get a Hello TMatic message
      description: Returns a simple "Hello, TMatic!" message.
      responses:
        '200':
          description: Successful response
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/HelloResponse'
      x-tmatic-data-model: HelloResponse
      x-tmatic-response-model: HelloResponse
      x-rest-action: list
components:
  schemas:
    HelloResponse:
      type: object
      properties:
        message:
          type: string
          x-tmatic-field-is-unique: true
      example:
        message: Hello, TMatic!
      x-tmatic-model-role: entity
      x-tmatic-entity-id: message
`.slice(1);

export const loadSpec = (value: string): unknown => {
  try {
    return jsYaml.load(value);
  } catch (e) {
    return undefined;
  }
};

export const normalizeName = (name: string): string =>
  name
    .toLocaleLowerCase('en-US')
    .replace(/[^a-z\d-_]/g, '-')
    .replace(/--/g, '-');

export const prepareRequestPayload = (data: CreateAPIPayload): { data: string; name: string } | never => {
  const spec = loadSpec(data.spec || '') as any;
  if (!_.isEmpty(spec)) {
    if (!_.isString(spec.info?.title) || !_.size(spec.info?.title)) {
      throw new Error('Invalid specification file');
    }
    const name = normalizeName(spec.info.title);
    return {
      name,
      data: JSON.stringify({
        templateRef: 'template:default/create-openapi',
        values: {
          name,
          owner: data.owner,
          system: data.system,
          description: data.description,
          definition: data.spec,
        },
      }),
    };
  }

  if (_.isString(data.name)) {
    const name = normalizeName(data.name);
    return {
      name,
      data: JSON.stringify({
        templateRef: 'template:default/create-openapi',
        values: {
          name,
          owner: data.owner,
          system: data.system,
          description: data.description,
          definition: _.isString(data.url) ? data.url : defaultTemplate(data.version),
        },
      }),
    };
  }

  throw new Error('No API name specified');
};

export const useCreateAPI = () => {
  const [showModal, setShowModal] = useState(false);
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();

  const onSubmit = async (data: CreateAPIPayload) => {
    try {
      const payload = prepareRequestPayload(data);
      const entity = await catalogApi.getEntityByRef({ name: payload.name, namespace: 'default', kind: 'api' }).catch(() => undefined);
      if (entity) {
        throw new Error(`API with name "${payload.name}" already exists`);
      }

      const { id } = await catalogApi.performCustomRequest({
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        url: { suffix: 'scaffolder/v2/tasks' },
        body: payload.data,
      });

      await awaitExecutionFinish(catalogApi, id);
      navigate(`/catalog/default/api/${payload.name}`);
    } catch (e) {
      errorApi.post(e);
    }
  };

  return {
    showModal,
    setShowModal,
    onSubmit,
  };
};
