import { JSONSchema7 } from 'json-schema';

export const NPM_CONFIG_PLACEHOLDER: string = 'npm'; // ???

export const NPM: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'NPM' },
    token: {
      type: 'string',
      title: 'Token',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
  },
  required: ['name', 'token'],
};
