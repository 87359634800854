import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { GetEntitiesRequest } from '@backstage/catalog-client';
import _ from 'lodash';
import { BaseEntity } from '@tmatic/entity-management-common';

export interface HookResult<Entity extends BaseEntity = BaseEntity> {
  entities: Entity[];
  loading: boolean;
  error?: Error;
}

export type Hook<Entity extends BaseEntity = BaseEntity> = () => HookResult<Entity>;

export function getUseListEntities<Entity extends BaseEntity = BaseEntity>(request: GetEntitiesRequest): () => HookResult<Entity> {
  return (): HookResult<Entity> => {
    const catalogApi = useApi(catalogApiRef);
    const { loading, value, error } = useAsync(async () => {
      const { items } = await catalogApi.getEntities(request);
      return _.filter(items, Boolean);
    }, [request]);

    return {
      entities: (value || []) as Entity[],
      loading,
      error,
    };
  };
}
