import { JSONSchema7 } from 'json-schema';

export const GITHUB_CONFIG_PLACEHOLDER: string = 'integrations.github';
export const GITHUB: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'GitHub' },
    host: { type: 'string', title: 'host', default: 'github.com', minLength: 1 },
    token: {
      type: 'string',
      title: 'token',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    apiBaseUrl: { type: 'string', title: 'apiBaseUrl' },
  },
  required: ['name', 'host', 'token'],
};

export const GITHUB_APPS_CONFIG_PLACEHOLDER: string = 'integrations.github'; // [i].apps

export const GITHUB_APPS: JSONSchema7 = {
  type: 'object',
  // description: "You can't change GitHub App settings manually. You can either delete it and add new App integration or just add new one",
  properties: {
    appId: {
      type: 'string',
      title: 'App ID',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    clientId: {
      type: 'string',
      title: 'Client Id',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    clientSecret: {
      type: 'string',
      title: 'Client Secret',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    privateKey: { type: 'string', title: 'Private Key' },
    webhookSecret: {
      type: 'string',
      title: 'Webhook Secret',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    webhookUrl: {
      type: 'string',
      title: 'Webhook Url',
    },
  },
};

export interface GitHubApp {
  appId: number;
  clientId: string;
  clientSecret: string;
  webhookSecret: string;
  privateKey: string;
}

export interface HiddenGitHubApp {
  $include: string;
}

export interface GitHubIntegration {
  uid?: string;
  apps?: GitHubApp[] | HiddenGitHubApp[];
  host: string;
  provider: string;
  name: string;
  owner: string;
  installationId: number;
}
