import { JSONSchema7 } from 'json-schema';

export const ResourceK8SAddClusterPayloadSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    owner: { type: 'string', minLength: 1 },
    system: { type: 'string', minLength: 1 },
    description: { type: 'string', maxLength: 1022 },
    clusterData: {
      type: 'object',
      properties: {
        config: {
          type: 'object',
          properties: {
            bearerToken: { type: 'string', minLength: 1 },
            tlsClientConfig: {
              type: 'object',
              properties: {
                insecure: { type: 'boolean' },
                caData: { type: 'string', minLength: 1 },
              },
              required: ['insecure', 'caData'],
            },
          },
          required: ['bearerToken', 'tlsClientConfig'],
        },
        name: { type: 'string', format: 'k8s-name', minLength: 1 },
        server: { type: 'string', format: 'url', minLength: 1 },
      },
      required: ['config', 'name', 'server'],
    },
  },
  required: ['owner', 'system', 'clusterData'],
};
