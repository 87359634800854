import { JSONSchema7 } from 'json-schema';

export const getClaimDefaults = (): JSONSchema7 => ({
  type: 'object',
  properties: {
    metadata: {
      type: 'object',
      properties: {
        name: { type: 'string', minLength: 1, maxLength: 255, title: 'Resource Name', format: 'k8s-name' },
        description: { type: 'string', minLength: 1, maxLength: 255, title: 'Description' },
        owner: { type: 'string', minLength: 1, title: 'Owner' },
        system: { type: 'string', minLength: 1, maxLength: 255, title: 'System', format: 'k8s-name' },
      },
    },
  },
});
