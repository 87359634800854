import { BackstageTheme } from '@backstage/theme';
import { Resource } from '@tmatic/entity-management-common';
import { RecursivePartial } from '@tmatic/utils';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ClaimForm } from './ClaimForm';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    filterBox: {
      padding: '1em',
      border: '1px solid #E4E4EB',
    },
    createBtn: { width: '100%' },
    justifyContentEnd: { textAlign: 'right' },
    modal: { overflow: 'scroll' },
    modalBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '66%',
      backgroundColor: theme.palette.background.paper,
      padding: '1rem',
      borderRadius: '5px',
      p: 4,
    },
  };
});

export interface ClaimFormButtonProps<FormData extends object> {
  resource: Resource; // "composite resource definition" Entity record
  controlPlaneName: string;
  formData?: RecursivePartial<FormData>;
}

export const ClaimFormModal = forwardRef(<FormData extends object>(props: ClaimFormButtonProps<FormData>, ref: any) => {
  const classes = useStyles();
  const { resource, controlPlaneName, formData } = props;
  const [modalContext, setModalContext] = useState<Resource | undefined>();

  const closeModal = () => setModalContext(undefined);
  const openModal = () => setModalContext(resource);

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));

  return (
    <Modal className={classes.modal} open={!!modalContext} onClose={closeModal}>
      <Box className={classes.modalBox}>
        <ClaimForm<FormData> resource={modalContext!} controlPlaneName={controlPlaneName} onClose={closeModal} formData={formData} />
      </Box>
    </Modal>
  );
});
