import { RE_K8S_NAME } from '@tmatic/configurator-common';
import { RecursivePartial } from '@tmatic/utils';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from '@rjsf/material-ui';
import { customizeValidator } from '@rjsf/validator-ajv8';
import { WidgetProps } from '@rjsf/utils';
import { IChangeEvent, ISubmitEvent } from '@rjsf/core';
import React, { FormEvent, useState } from 'react';
import { JSONSchema7 } from 'json-schema';
import { UI_CUSTOMIZATIONS, UI_DEFAULTS } from '../../templates';
import { normalizeJsonSchema } from './utils';
import { Localizer } from '@rjsf/validator-ajv8/src/types';
import _ from 'lodash';

const localizer: Localizer = errors => {
  _.forEach(errors, error => {
    if (error.keyword === 'format' && error.params.format === 'k8s-name') {
      error.message = 'must start and end with alphanumeric characters ([a-z0-9]) and can contain hyphens (-) in between.';
    }
  });
};

const customFormats = {
  'k8s-name': RE_K8S_NAME,
};

const validator = customizeValidator({ customFormats }, localizer);

export interface JsonSchemaFormProps<FormData extends Record<string, any> = Record<string, any>> {
  widgets?: { [key: string]: WidgetProps };
  schema: JSONSchema7;
  formData?: FormData;
  onSubmit: (...args: any[]) => void;
  onClose: () => void;
  loading?: boolean;
}

export const useStyles = makeStyles(() =>
  createStyles({
    justifyContentEnd: { textAlign: 'right' },
    actionBtn: { marginLeft: '1em' },
  }),
);

export function JsonSchemaForm<FormData extends Record<string, any> = Record<string, any>>(props: JsonSchemaFormProps<FormData>) {
  const { widgets, schema, formData, onSubmit, onClose, loading } = props;

  const classes = useStyles();
  const [state, setState] = useState<RecursivePartial<FormData>>(formData || {});
  // apiPath toggle
  // descriptions

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
        <Form
          liveValidate
          validator={validator}
          widgets={widgets}
          schema={normalizeJsonSchema({ schema })}
          uiSchema={{ ...UI_DEFAULTS, ...UI_CUSTOMIZATIONS.CLAIM_FORM }}
          formData={state}
          onChange={(event: IChangeEvent<FormData>) => {
            setState(event.formData);
          }}
          onSubmit={(e: ISubmitEvent<FormData>, nativeEvent: FormEvent<HTMLFormElement>) => {
            nativeEvent.preventDefault();
            onSubmit(e.formData);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.justifyContentEnd}>
              <Button className={classes.actionBtn} color="secondary" variant="contained" onClick={onClose}>
                Close
              </Button>
              <Button className={classes.actionBtn} color="primary" variant="contained" type="submit" disabled={loading}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
}
