import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { WidgetProps } from '@rjsf/utils';
import React, { useState } from 'react';
import { useStyles } from './common';

export const MultiSelectField = (props: WidgetProps<any, any>) => {
  const classes = useStyles();
  const {
    id,
    formData,
    onChange,
    schema: {
      items: { enum: options },
      title,
    },
  } = props;
  const [selectedItems, setSelectedItems] = useState(formData || []);
  return (
    <div className={classes.root}>
      <label htmlFor={id}>{title}</label>
      <Select
        id={id}
        className={classes.root}
        multiple
        value={selectedItems}
        onChange={event => {
          setSelectedItems(event.target.value);
          onChange(event.target.value);
        }}
        // Adjust the maxHeight as needed
        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
        inputProps={{
          id: id,
        }}
      >
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
