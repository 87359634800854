import { TableColumn } from '@backstage/core-components';
import { ApiEntity, ComponentEntity, Entity } from '@backstage/catalog-model';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { ArtifactEntity } from '@tmatic/plugin-artifact-common';
import { ApplicationEntity } from '@tmatic/plugin-application-common';

export const apiEntityColumnsReduced: TableColumn<ApiEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'API' }),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const artifactEntityColumnsReduced: TableColumn<ArtifactEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'Artifact' }),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const componentEntityColumns: TableColumn<ComponentEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'Component' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const applicationEntityColumns: TableColumn<ApplicationEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'Application' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

export const EntityColumns: TableColumn<Entity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'Entity' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
