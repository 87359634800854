import { ObjectFieldTemplateProps } from '@rjsf/utils';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useStyles } from './common';
import _ from 'lodash';

export function ObjectCardTemplate(props: ObjectFieldTemplateProps) {
  const classes = useStyles();
  const hasTitle = !!props.title.length;

  return (
    <Box className={classes.objectRoot}>
      {hasTitle && (
        <div className={classes.cardHeader}>
          <Typography variant="h6">{props.title}</Typography>
          <Divider />
          {props.description}
        </div>
      )}
      <Box className={classes.cardContent}>
        {_.map(props.properties, (element, key) => (
          <div className={classes.objectItem} key={key}>
            {element.content}
          </div>
        ))}
      </Box>
    </Box>
  );
}
