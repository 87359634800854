import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { TMATIC_ANNOTATION } from '@tmatic/constants-common';
import { safeYamlLoad } from '@tmatic/utils';
import React from 'react';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';

interface KeyValueObject {
  key: string;
  value: string;
}

const columns: TableColumn<KeyValueObject>[] = [
  { title: 'Key', field: 'key' },
  { title: 'Value', field: 'value' },
];

const buildKey = (key: string, prefix?: string) => (prefix ? `${prefix}["${key}"]` : key);

const pickValues = (record: Record<string, any>, prefix?: string) =>
  _.reduce<[string, any], KeyValueObject[]>(
    _.entries(record),
    (acc, [key, value]) => {
      switch (true) {
        case _.isArray(value): {
          acc.push({ key: buildKey(key, prefix), value: JSON.stringify(value) });
          break;
        }
        case _.isObject(value): {
          acc.push(...pickValues(value, buildKey(key, prefix)));
          break;
        }
        default: {
          acc.push({ key: buildKey(key, prefix), value });
          break;
        }
      }
      return acc;
    },
    [],
  );

const loadParametersFromEntity = (entity: Entity): KeyValueObject[] | null => {
  const specDefinition = entity.spec?.definition;
  if (!_.isString(specDefinition)) {
    return null;
  }

  const manifest = safeYamlLoad<{ spec: Record<string, any> }, null>(specDefinition);
  return _.isObject(manifest?.spec) ? pickValues(manifest!.spec) : null;
};

export const ClaimParametersCard = () => {
  const { entity } = useEntity();
  const claimParameters = loadParametersFromEntity(entity);

  const cardTitle = 'Claim Parameters';

  return <CardTMatic<KeyValueObject> data={claimParameters || []} cardTitle={cardTitle} columns={columns} />;
};

export const isPluginApplicableToEntity = (entity: Entity) =>
  entity.kind === 'Resource' && entity.metadata?.annotations?.[TMATIC_ANNOTATION.CROSSPLANE_RESOURCE_API_KIND] === 'claim';
