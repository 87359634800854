import { JSONSchema7 } from 'json-schema';

// todo: implement multiple providers along with UI upon product's request
// currently supported GitHub only
export const OAUTH_CONFIG_PLACEHOLDER: string = 'auth.providers.github';

export const OAUTH: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'OAuth' },
    clientId: {
      type: 'string',
      title: 'Client ID',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    clientSecret: {
      type: 'string',
      title: 'Client Secret',
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
  },
  required: ['name', 'clientId', 'clientSecret'],
};
