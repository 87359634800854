import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import _ from 'lodash';
import React from 'react';

export interface CustomInternalLinkProps {
  entity: Entity;
  target?: string;
}

export const CustomInternalLink = ({ entity, target }: CustomInternalLinkProps) => {
  const { kind, metadata } = entity;
  switch (target) {
    case 'system': {
      if (!_.isString(entity.spec?.system)) {
        return <></>;
      }
      const ref = parseEntityRef(entity.spec!.system, {
        defaultKind: 'system',
        defaultNamespace: 'default',
      });
      return <Link to={`/catalog/${ref.namespace}/${ref.kind}/${ref.name}`}>{ref.name}</Link>;
    }
    case 'owner': {
      if (!_.isString(entity.spec?.owner)) {
        return <></>;
      }
      const ref = parseEntityRef(entity.spec!.owner, {
        defaultKind: 'group',
        defaultNamespace: 'default',
      });
      return <Link to={`/catalog/${ref.namespace}/${ref.kind}/${ref.name}`}>{ref.name}</Link>;
    }
    default: {
      return <Link to={`/catalog/${metadata?.namespace || 'default'}/${_.toLower(kind)}/${metadata.name}`}>{metadata.name}</Link>;
    }
  }
};
