import { JSONSchema7 } from 'json-schema';

export const PAGERDUTY_CONFIG_PLACEHOLDER: string = 'proxy./pagerduty';

export const PAGERDUTY: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'PagerDuty' },
    pagerduty: {
      type: 'object',
      title: '',
      properties: {
        eventsBaseUrl: { type: 'string', title: 'Event base URL', minLength: 1 },
      },
      required: ['eventsBaseUrl'],
    },
    headers: {
      type: 'object',
      title: '',
      properties: {
        Authorization: {
          type: 'string',
          title: 'Token',
          minLength: 1,
          description: 'This field contains sensitive information. On edit screen its content will be hidden',
        },
      },
      required: ['Authorization'],
    },
    target: { type: 'string', title: 'Target', minLength: 1 },
  },
  required: ['name', 'pagerduty', 'headers', 'target'],
};
