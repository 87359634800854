import { PlaceholderSettings } from '../types';
import { DOCKER_REGISTRY, DOCKER_REGISTRY_TYPE, DOCKER_REGISTRY_CONFIG_PLACEHOLDER } from './docker';

export * from './types';

export const DISCOVERY_SCHEMAS: Record<string, PlaceholderSettings> = {
  DOCKER_REGISTRY: {
    schema: DOCKER_REGISTRY,
    type: DOCKER_REGISTRY_TYPE,
    configPlaceholder: DOCKER_REGISTRY_CONFIG_PLACEHOLDER,
    allowsMultipleRecords: true,
  },
};
