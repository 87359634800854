import { JSONSchema7 as JSONSchema } from 'json-schema';
import _ from 'lodash';
import { constants as C } from '../config';
import { getBaseEntitySchemaCopy } from './BaseEntity.schema';

export const SystemSchema: JSONSchema = _.merge({}, getBaseEntitySchemaCopy(['properties.kind', 'properties.spec.properties.type']), {
  properties: {
    kind: { const: C.KIND_SYSTEM },
    spec: {
      properties: {
        domain: { type: 'string' },
      },
    },
  },
});
