import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeSnippet, InfoCard, Progress, Table, TableColumn, TableProps, WarningPanel } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import { TMATIC_ANNOTATION_LINK_NPM, TMATIC_ANNOTATION_LINK_NPM_API } from '@tmatic/constants-common';
import { choreSize } from '@tmatic/utils';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import ClickToCopy from './ClickToCopy';
import { useTheme } from '@mui/material/styles';
import { Link } from '@backstage/core-components';

const useStyles = makeStyles(() => ({
  subheader: {
    fontWeight: 'bold',
  },
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: useTheme<BackstageTheme>().palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  gridItemCardContent: { flex: 1 },
}));

export type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface NpmContentRecord {
  name: string;
  link: string;
  version: string;
  versionCreated: string;
  packageSize: string;
  description: string;
}

interface PackageJson {
  name: string;
  version: string;
  description: string;
  license: string;
  main: string;
  dist: {
    fileCount: number;
    unpackedSize: number;
  };
}

interface PackageMetadata {
  _id: string;
  name: string;
  description: string;
  version: string;
  homepage: string;
  'dist-tags': {
    latest: string;
  };
  versions: Record<string, PackageJson>;
  time: Record<string, string> & { created: string; modified: string };
}

interface ComponentState {
  loading: boolean;
  value: PackageMetadata | null;
  error: Error | null;
}

const tableOptions: TableProps['options'] = {
  toolbar: false,
  paging: true,
  search: false,
  draggable: false,
  sorting: false,
  padding: 'dense',
  emptyRowsWhenPaging: false,
};

export const NpmPublicPackageInfoCard = () => {
  const variant = 'gridItem';
  const { entity } = useEntity();
  const classes = useStyles();
  const theme = useTheme<BackstageTheme>();

  const [state, setState] = useState<ComponentState>({
    loading: false,
    value: null,
    error: null,
  });
  const { value: packageMetadata, loading, error } = state;

  const controller = useMemo(() => new AbortController(), []);

  const tmaticAnnotationLinkNpmApi = entity.metadata.annotations?.[TMATIC_ANNOTATION_LINK_NPM_API];
  const tmaticAnnotationLinkNpm = entity.metadata.annotations?.[TMATIC_ANNOTATION_LINK_NPM];
  const fetchMetadata = useCallback(() => {
    const mergeState = (props: Partial<ComponentState>) => setState({ ...state, ...props });
    let registryApiLink: string | undefined = tmaticAnnotationLinkNpmApi;
    if (!registryApiLink) {
      // temporary support of old artifacts without TMATIC_ANNOTATION_LINK_NPM_API
      if (_.isString(tmaticAnnotationLinkNpm)) {
        registryApiLink = tmaticAnnotationLinkNpm.replace('www.npmjs.org/packages', 'registry.npmjs.org');
      }
    }
    if (_.isString(registryApiLink)) {
      mergeState({ loading: true });
      axios
        .get(`${registryApiLink}?ts=${Date.now()}`, { signal: controller.signal })
        .then(({ data }) => {
          mergeState({ value: data, loading: false });
        })
        .catch(() => {
          mergeState({
            loading: false,
            error: new Error('Error fetching Package Info or Package Not Found'),
            value: null,
          });
        });
    }
  }, [state, setState, controller.signal, tmaticAnnotationLinkNpmApi, tmaticAnnotationLinkNpm]);

  useEffect(() => {
    fetchMetadata();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller]);

  const refreshEntity = useCallback(fetchMetadata, [fetchMetadata]);

  if (loading) {
    return (
      <InfoCard variant={variant} title="Package Info">
        <Progress />
      </InfoCard>
    );
  }

  if (error || !packageMetadata) {
    return (
      <InfoCard variant={variant} title="Package Info">
        <WarningPanel severity="error" title="Could not load Package Info" message={<CodeSnippet text={`${error}`} language="text" />} />
      </InfoCard>
    );
  }

  const tableData: NpmContentRecord[] = [];

  for (const key in packageMetadata.versions) {
    if (packageMetadata.versions.hasOwnProperty(key)) {
      tableData.push({
        name: `${packageMetadata.versions[key].name} ${key}`,
        link: `${tmaticAnnotationLinkNpmApi}/v/${key}`,
        version: key,
        versionCreated: moment(packageMetadata.time[key]).format('YYYY-MM-DD HH:mm:ss'),
        packageSize: choreSize(packageMetadata.versions[key].dist.unpackedSize),
        description: packageMetadata.versions[key].description,
      });
    }
  }

  const columns: TableColumn<NpmContentRecord>[] = [
    {
      title: 'Name',
      render: rowData => {
        return <Link to={rowData.link}>{rowData.name}</Link>;
      },
      highlight: true,
    },
    { title: 'Created', field: 'versionCreated' },
    { title: 'Package Size', field: 'packageSize' },
    { title: 'Description', field: 'description' },
  ];

  const latest = packageMetadata['dist-tags'].latest;
  const packageJson: PackageJson = (packageMetadata as PackageMetadata).versions[latest];

  const styles: React.CSSProperties = {};
  if (theme.palette.type === 'dark') {
    styles.backgroundColor = '#28293c';
  }

  return (
    <Card>
      <CardHeader
        title="Versions"
        style={{ paddingBottom: '15px', ...styles }}
        action={
          <IconButton aria-label="Refresh" title="Schedule entity refresh" onClick={refreshEntity} size="large">
            <CachedIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent className={classes.gridItemCardContent} style={{ ...styles }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.label}>
              Use
            </Typography>
            <ClickToCopy textToCopy={`npm install ${packageJson.name}`} />
          </Grid>
          <Grid item xs={12}>
            <Table<NpmContentRecord> columns={columns} options={tableOptions} data={tableData} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

/** @public */
export const isPluginApplicableToEntity = (entity: Entity) => entity.spec?.type === 'npm';
