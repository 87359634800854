import { ObjectFieldTemplateProps } from '@rjsf/utils';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useStyles } from './common';
import _ from 'lodash';

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  const classes = useStyles();
  const hasTitle = !!props.title.length;

  return (
    <div className={classes.objectRoot}>
      {hasTitle && (
        <>
          <Typography variant="h6">{props.title}</Typography>
          <Divider />
          {props.description}
        </>
      )}

      {_.map(props.properties, (element, key) => (
        <div className={classes.objectItem} key={key}>
          {element.content}
        </div>
      ))}
    </div>
  );
}
