import _, { trimEnd } from 'lodash';
import React from 'react';
import { LinkProps } from './LinkTMatic';
import { ANNOTATION_LOCATION, Entity } from '@backstage/catalog-model';
import { TMATIC_ANNOTATION_COMPONENT_GIT_REPO_NAME, TMATIC_ANNOTATION_COMPONENT_GIT_REPO_OWNER, TMATIC_ANNOTATION_COMPONENT_ORG_PREFIX } from '@tmatic/constants-common';
// eslint-disable-next-line no-restricted-imports
import url from 'url';

export const isExternalUri = (uri: string) => /^([a-z+.-]+):/.test(uri);

// See https://github.com/facebook/react/blob/f0cf832e1d0c8544c36aa8b310960885a11a847c/packages/react-dom-bindings/src/shared/sanitizeURL.js
export const SCRIPT_PROTOCOL_PATTERN =
  // eslint-disable-next-line no-control-regex
  /^[\u0000-\u001F ]*j[\r\n\t]*a[\r\n\t]*v[\r\n\t]*a[\r\n\t]*s[\r\n\t]*c[\r\n\t]*r[\r\n\t]*i[\r\n\t]*p[\r\n\t]*t[\r\n\t]*:/i;

// We install this globally in order to prevent javascript: URL XSS attacks via window.open
export const originalWindowOpen = window.open as typeof window.open & {
  __backstage?: true;
};

if (originalWindowOpen && !originalWindowOpen.__backstage) {
  const newOpen = function open(this: Window, ...args: Parameters<typeof window.open>) {
    const _url = String(args[0]);
    if (SCRIPT_PROTOCOL_PATTERN.test(_url)) {
      throw new Error('Rejected window.open() with a javascript: URL as a security precaution');
    }
    return originalWindowOpen.apply(this, args);
  };
  newOpen.__backstage = true;
  window.open = newOpen;
}

/**
 * Get the app base path from the configured app baseUrl.
 * The returned path does not have a trailing slash.
 */
export const getBasePath = (baseUrl?: string) => {
  // baseUrl can be specified as just a path
  const base = 'http://sample.dev';
  const _url = baseUrl ?? '/';
  const { pathname } = new URL(_url, base);
  return trimEnd(pathname, '/');
};

export const getResolvedPath = (baseUrl: string | undefined, uri: LinkProps['to']) => {
  let resolvedPath = String(uri);

  const basePath = getBasePath(baseUrl);
  const external = isExternalUri(resolvedPath);
  const startsWithBasePath = resolvedPath.startsWith(basePath);

  if (!external && !startsWithBasePath) {
    resolvedPath = basePath.concat(resolvedPath);
  }

  return resolvedPath;
};

/**
 * Given a react node, try to retrieve its text content.
 */
export const getNodeText = (node: React.ReactNode | React.ReactNode[]): string => {
  // If the node is an array of children, recurse and join.
  if (node instanceof Array) {
    return node.map(getNodeText).join(' ').trim();
  }

  // If the node is a react element, recurse on its children.
  if (typeof node === 'object' && node) {
    return getNodeText((node as React.ReactElement)?.props?.children);
  }

  // Base case: the node is just text. Return it.
  if (['string', 'number'].includes(typeof node)) {
    return String(node);
  }

  // Base case: just return an empty string.
  return '';
};

export const extractRepositoryInfo = (entity: Entity): { gitRepositoryOwner?: string; gitRepositoryName?: string; orgPrefix?: string } => {
  const annotations = entity.metadata.annotations || {};

  let gitRepositoryName = annotations[TMATIC_ANNOTATION_COMPONENT_GIT_REPO_NAME];
  let gitRepositoryOwner = annotations[TMATIC_ANNOTATION_COMPONENT_GIT_REPO_OWNER];
  const orgPrefix = annotations[TMATIC_ANNOTATION_COMPONENT_ORG_PREFIX];

  if (!gitRepositoryOwner || !gitRepositoryName) {
    const annotationLocation = annotations[ANNOTATION_LOCATION] || '';
    const link = annotationLocation.slice(annotationLocation?.indexOf(':') + 1);
    const { host, pathname } = url.parse(link);
    if (_.isString(pathname) && _.size(pathname)) {
      // eslint-disable-next-line default-case
      switch (host) {
        case 'github.com': {
          const chunks = pathname.split('/').filter(Boolean);
          if (!gitRepositoryOwner && chunks[0]) {
            gitRepositoryOwner = chunks[0];
          }
          if (!gitRepositoryName && chunks[1]) {
            gitRepositoryName = chunks[1];
          }
          break;
        }
      }
    }
  }

  return {
    gitRepositoryOwner,
    gitRepositoryName,
    orgPrefix,
  };
};

export const pickAttributes = (entity: Entity) => ({
  componentName: entity.metadata.name,
  artifactTitle: entity.metadata.name,
  artifactDescription: _.map(entity.metadata.name.split('-'), (chunk, index) => (!index ? _.capitalize(chunk) : chunk)).join(' '),
});

export const extractAnnotation = (entity: Entity, annotation: string): string | undefined => {
  const annotations: unknown = _.get(entity, 'metadata.annotations', {});
  return (annotations as Record<string, string>)?.[annotation];
};
