import { ErrorObject } from 'ajv/lib/types';
import FormHelperText from '@mui/material/FormHelperText';
import _ from 'lodash';
import React from 'react';

export interface FormErrorMessageProps {
  wasChanged: Set<string>;
  errors: ErrorObject[] | null;
  property: string;
  prefix?: string;
}

export const FormErrorMessage = ({ errors, wasChanged, property, prefix }: FormErrorMessageProps) => {
  const instancePath = prefix ? `/${prefix}/${property}` : `/${property}`;
  const propertyPath = prefix ? `${prefix}.${property}` : property;

  let error;
  // eslint-disable-next-line no-cond-assign
  if (wasChanged.has(propertyPath) && (error = _.find(errors, { instancePath }))) {
    return <FormHelperText error>{error.message}</FormHelperText>;
  }
  return null;
};
