import { useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef } from '@backstage/integration-react';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { useAsync } from 'react-use';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import url from 'url';

interface UseGitCredentialsProps {
  repositoryUrl: string;
  uiSchema: any;
}

export const useGitRepoOrganizations = (props: UseGitCredentialsProps) => {
  const { uiSchema, repositoryUrl } = props;
  const scmAuthApi = useApi(scmAuthApiRef);
  const { setSecrets } = useTemplateSecrets();

  useAsync(async () => {
    const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
    if (!requestUserCredentials) {
      return;
    }

    const { host, pathname } = url.parse(repositoryUrl);
    if (!_.isString(host) || !_.isString(pathname)) {
      return;
    }
    const [workspace, repository] = _.filter(pathname.split('/'), Boolean);
    // todo: capture with try-catch and notify if user has no access to the repository
    const { token } = await scmAuthApi.getCredentials({
      url: `https://${host}/${workspace}/${repository}`,
      additionalScope: {
        repoWrite: true,
        customScopes: requestUserCredentials.additionalScopes,
      },
    });

    // set the secret using the key provided in the ui:options for use
    // in the templating the manifest with ${{ secrets[secretsKey] }}
    setSecrets({ [requestUserCredentials.secretsKey]: token });
    return;
  }, [repositoryUrl, uiSchema]);
};
