import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { TableColumn } from '@backstage/core-components';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ModalContainer } from '../modals/ModalContainer';
import makeStyles from '@mui/styles/makeStyles';
import { CardTMatic } from '../CardTMatic';

const useStyles = makeStyles(() => ({
  viewButton: { paddingLeft: '5px', paddingRight: '5px' },
}));

interface KeyValueObject {
  key: string;
  value: string;
  origin: string;
}

const ModalWindow = (props: any) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button className={classes.viewButton} variant="outlined" color="primary" onClick={() => setShowModal(true)}>
        <DescriptionIcon />
        View YAML
      </Button>
      <ModalContainer title="YAML" open={showModal} toggleModal={() => setShowModal(!showModal)}>
        <Box>
          <pre>{props.origin}</pre>
        </Box>
      </ModalContainer>
    </>
  );
};

const columns: TableColumn<KeyValueObject>[] = [
  { title: 'Type', field: 'type' },
  { title: 'Name', field: 'name' },
  { title: 'Status', field: 'status' },
  { title: 'Kind', field: 'kind' },
  { title: 'Action', render: rowData => <ModalWindow origin={rowData.origin} /> },
];

export const ClaimDetailsCard = () => {
  const { entity } = useEntity();
  const cardTitle = 'Claim Details';

  const catalogApi = useApi(catalogApiRef);
  const clusterName = entity.metadata.namespace?.substring(3);

  const { value, loading, error } = useAsync(() => {
    return catalogApi.performCustomRequest({
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: { suffix: `k8s/${clusterName}/claim-details?claimref=${stringifyEntityRef(entity)}` },
    });
  }, [catalogApi]);

  return <CardTMatic<KeyValueObject> data={value as KeyValueObject[]} cardTitle={cardTitle} columns={columns} loading={loading} error={error} />;
};
