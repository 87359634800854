import React from 'react';
import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { artifactEntityColumnsReduced } from '../presets';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';
import { ArtifactEntity } from '@tmatic/plugin-artifact-common';

export const ComponentArtifactCard = (props: { columns?: TableColumn<ArtifactEntity>[] }) => {
  const { columns = artifactEntityColumnsReduced } = props;
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
  });
  const cardTitle = 'Artifacts';

  const rowData = _.filter(entities, item => item.kind.toLocaleLowerCase('en-US') === 'artifact');

  return <CardTMatic<ArtifactEntity> data={rowData as ArtifactEntity[]} cardTitle={cardTitle} columns={columns} loading={loading} error={error} />;
};
