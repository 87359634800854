import { CatalogApi } from '@backstage/catalog-client';
import { tOut } from '@tmatic/utils';

export const awaitExecutionFinish = async (catalogApi: CatalogApi, taskId: string) => {
  // eslint-disable-next-line no-constant-condition
  while (1 > 0) {
    const task = await catalogApi.performCustomRequest({ method: 'GET', url: { suffix: `scaffolder/v2/tasks/${taskId}` } });
    if (task.status === 'completed') {
      await tOut(500);
      break;
    }
    if (task.status === 'failed') {
      throw new Error('Failed creating API. Please, try again');
    }
    await tOut(500);
  }
};
