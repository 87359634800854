import { BackstageTheme } from '@backstage/theme';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React, { useRef } from 'react';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    label: {
      color: theme.palette.text.secondary,
      textTransform: 'lowercase',
      fontSize: '12px',
      fontWeight: 'bold',
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      border: '1px solid',
      borderRadius: '3px',
      borderColor: theme.palette.text.secondary,
      padding: '8px 20px',
      width: '100%',
    },
    input: {
      position: 'fixed',
      opacity: 0,
      cursor: 'pointer',
    },
  };
});

const ClickToCopy = ({ textToCopy }: { textToCopy: string }) => {
  const textRef = useRef<null | HTMLInputElement>(null);
  const handleCopy = () => {
    if (textRef.current) {
      textRef.current!.select();
      document.execCommand('copy');
    }
  };

  const classes = useStyles();
  return (
    <Typography variant="h2" className={classes.label} onClick={handleCopy}>
      <input className={classes.input} ref={textRef} type="text" value={textToCopy} readOnly />
      {textToCopy}
    </Typography>
  );
};

export default ClickToCopy;
