import { TableColumn } from '@backstage/core-components';
import { choreSize } from '@tmatic/utils';
import moment from 'moment';

export interface TableRecord {
  imageTags: string[];
  imagePushedAt: string;
  imageSizeInBytes: number;
  imageDigest: string;
  id: number;
}

export const columns: TableColumn<TableRecord>[] = [
  {
    field: 'imageTags',
    title: 'Image Tag',
    render: row => [row.imageTags].flat().join(', '),
  },
  {
    field: 'imagePushedAt',
    title: 'Created',
    render: row => moment(row.imagePushedAt).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    field: 'imageSizeInBytes',
    title: 'Image Size',
    render: row => choreSize(row.imageSizeInBytes),
  },
  { field: 'imageDigest', title: 'Digest' },
];
