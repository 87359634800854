import React from 'react';
import { TitleFieldProps } from '@rjsf/utils';
import Typography from '@mui/material/Typography';
import { useStyles } from './common';

export function TitleFieldTemplate(props: TitleFieldProps) {
  const classes = useStyles();
  const { id, title } = props;
  return (
    <Typography id={id} variant="h6" className={classes.childrenHeader}>
      {title}
    </Typography>
  );
}
