import React from 'react';
import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { applicationEntityColumns } from '../presets';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';
import { ApplicationEntity } from '@tmatic/plugin-application-common';

export const HasApplicationCard = (props: { columns?: TableColumn<ApplicationEntity>[] }) => {
  const { columns = applicationEntityColumns } = props;
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
  });

  const cardTitle = 'Applications';

  const rowData = _.filter(entities, item => item.kind.toLocaleLowerCase('en-US') === 'application');

  return <CardTMatic<ApplicationEntity> data={rowData as ApplicationEntity[]} cardTitle={cardTitle} columns={columns} options={{ paging: true }} loading={loading} error={error} />;
};
