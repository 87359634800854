import { Link, TableColumn, TableProps } from '@backstage/core-components';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { TMATIC_ANNOTATION_APPLICATION_ARTIFACT_REF, TMATIC_ANNOTATION_APPLICATION_COMPONENT_REF } from '@tmatic/constants-common';
import _ from 'lodash';
import { CardTMatic } from '../CardTMatic';

const tableOptions: TableProps['options'] = {
  paging: false,
  search: false,
  draggable: false,
  sorting: false,
  padding: 'dense',
};

interface KeyValueObject {
  artifactLink: JSX.Element | null;
  componentLink: JSX.Element | null;
}

const buildLinkFromEntityRef = (entityRef?: string) => {
  if (!entityRef) {
    return null;
  }
  const [kind, namespace, name] = _.split(entityRef, /[:/]/);
  const link = `/catalog/${namespace}/${kind}/${name}`;
  return <Link to={link}>{name}</Link>;
};

const columns: TableColumn<KeyValueObject>[] = [
  { highlight: true, title: 'Artifact', render: rowData => rowData.artifactLink },
  { highlight: true, title: 'Component', render: rowData => rowData.componentLink },
];

export const ApplicationArtifactCard = () => {
  const { entity } = useEntity();
  const cardTitle = 'Artifacts';
  const artifactRef = entity.metadata.annotations?.[TMATIC_ANNOTATION_APPLICATION_ARTIFACT_REF];
  const componentRef = entity.metadata.annotations?.[TMATIC_ANNOTATION_APPLICATION_COMPONENT_REF];
  const data: KeyValueObject[] = [{ artifactLink: buildLinkFromEntityRef(artifactRef), componentLink: buildLinkFromEntityRef(componentRef) }];

  return <CardTMatic<KeyValueObject> cardTitle={cardTitle} data={data} columns={columns} options={tableOptions} />;
};
