import { JSONSchema7 } from 'json-schema';

export const KUBERNETES_CONFIG_PLACEHOLDER: string = 'kubernetes.clusterLocatorMethods.0.clusters';

export const KUBERNETES: JSONSchema7 = {
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name', minLength: 1, maxLength: 255, default: 'Kubernetes' },
    authProvider: { type: 'string', title: 'Auth Provider', minLength: 1 },
    serviceAccountToken: {
      type: 'string',
      title: 'Service Account Token',
      minLength: 1,
      description: 'This field contains sensitive information. On edit screen its content will be hidden',
    },
    skipMetricsLookup: { type: 'boolean', title: 'Skip Metrics Lookup', minLength: 1 },
    skipTLSVerify: { type: 'boolean', title: 'Skip TLS Verify', minLength: 1 },
  },
  required: ['name', 'authProvider', 'serviceAccountToken'],
};
