import { CompoundEntityRef, Entity, RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import { EntityRefLinks, getEntityRelations } from '@backstage/plugin-catalog-react';
import { MarkdownContent } from '@backstage/core-components';
import { TMATIC_ANNOTATION, TMATIC_LABELS } from '@tmatic/constants-common';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import _ from 'lodash';
import { CustomInternalLink } from '../CustomInternalLink';
import { AboutFieldTMatic } from './AboutFieldTMatic';

const useStyles = makeStyles({ description: { wordBreak: 'break-word' } });

/**
 * Props for {@link AboutContentTMatic}.
 *
 * @public
 */
export interface AboutContentTMaticProps {
  entity: Entity;
}

interface CommonBlockProps<E extends Entity = Entity> {
  isAPI: boolean;
  isApplication: boolean;
  isComponent: boolean;
  isLocation: boolean;
  isGroup: boolean;
  isResource: boolean;
  isSystem: boolean;
  isTemplate: boolean;
  partOfSystemRelations: CompoundEntityRef[];
  partOfComponentRelations: CompoundEntityRef[];
  partOfDomainRelations: CompoundEntityRef[];
  ownedByRelations: CompoundEntityRef[];
  entity: E;
}

const MetadataDescriptionBlock = ({ entity }: CommonBlockProps) => {
  const classes = useStyles();
  return (
    <AboutFieldTMatic label="Description" gridSizes={{ xs: 12 }}>
      <MarkdownContent className={classes.description} content={entity?.metadata?.description || 'No description'} />
    </AboutFieldTMatic>
  );
};

const SpecLifecycleBlock = ({ entity }: CommonBlockProps) => {
  const controlPlaneName = entity.metadata.annotations?.[TMATIC_ANNOTATION.CONTROL_PLANE_NAME];
  const controlPlaneResourceApiKind = entity.metadata.annotations?.[TMATIC_ANNOTATION.CROSSPLANE_RESOURCE_API_KIND];
  // start - do not render for control-plane dependent resources
  const isDependentResource = !!(controlPlaneName && controlPlaneResourceApiKind);

  if (!_.isString(entity.spec?.lifecycle) || isDependentResource) {
    return null;
  }
  return <AboutFieldTMatic label="Lifecycle" value={entity?.spec?.lifecycle as string} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />;
};

const SpecOwnerBlock = ({ entity }: CommonBlockProps) => {
  return _.isEmpty(entity.spec?.owner) ? null : (
    <AboutFieldTMatic label="Owner" value={<CustomInternalLink entity={entity} target="owner" />} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />
  );
};

const SpecSystemBlock = ({ entity }: CommonBlockProps) => {
  return _.toLower(entity.kind) === 'system' || _.isEmpty(entity.spec?.system) ? null : (
    <AboutFieldTMatic label="System" value={<CustomInternalLink entity={entity} target="system" />} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />
  );
};

const SpecTypeBlock = ({ entity }: CommonBlockProps) => {
  if (!_.isString(entity.spec?.type)) {
    return null;
  }
  return <AboutFieldTMatic label="Type" value={entity?.spec?.type as string} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />;
};

const SpecLanguage = ({ entity }: CommonBlockProps) => {
  const lang = entity.metadata.labels?.[TMATIC_LABELS.COMPONENT_LANGUAGE];
  if (!_.isString(lang)) {
    return null;
  }
  return <AboutFieldTMatic label="Language" value={lang as string} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />;
};

const SpecImportance = ({ entity }: CommonBlockProps) => {
  const importance = entity.metadata.labels?.[TMATIC_LABELS.COMPONENT_IMPORTANCE];
  if (!_.isString(importance)) {
    return null;
  }
  return <AboutFieldTMatic label="Importance" value={importance as string} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />;
};

/** @public */
export function AboutContentTMatic(props: AboutContentTMaticProps) {
  const { entity } = props;
  const isApplication = entity.kind.toLocaleLowerCase('en-US') === 'application';
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isLocation = entity.kind.toLocaleLowerCase('en-US') === 'location';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, { kind: 'system' });
  const partOfComponentRelations = getEntityRelations(entity, RELATION_PART_OF, { kind: 'component' });
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, { kind: 'domain' });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  const blockProps: CommonBlockProps = {
    isAPI,
    isApplication,
    isComponent,
    isLocation,
    isGroup,
    isResource,
    isSystem,
    isTemplate,
    partOfSystemRelations,
    partOfComponentRelations,
    partOfDomainRelations,
    ownedByRelations,
    entity,
  };

  return (
    <Grid container>
      <MetadataDescriptionBlock {...blockProps} />
      <SpecOwnerBlock {...blockProps} />
      {(isSystem || partOfDomainRelations.length > 0) && (
        <AboutFieldTMatic label="Domain" value="No Domain" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
          {partOfDomainRelations.length > 0 && <EntityRefLinks entityRefs={partOfDomainRelations} defaultKind="domain" />}
        </AboutFieldTMatic>
      )}
      <SpecSystemBlock {...blockProps} />
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutFieldTMatic label="Parent Component" value="No Parent Component" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
          <EntityRefLinks entityRefs={partOfComponentRelations} defaultKind="component" />
        </AboutFieldTMatic>
      )}
      <SpecTypeBlock {...blockProps} />
      <SpecLifecycleBlock {...blockProps} />
      <SpecLanguage {...blockProps} />
      <SpecImportance {...blockProps} />
    </Grid>
  );
}
