import { FieldValidation } from '@rjsf/core';
import jsYaml from 'js-yaml';
// import OpenAPISchemaValidator from 'openapi-schema-validator';
// import { OpenAPIV2, OpenAPIV3 } from 'openapi-types';
import _ from 'lodash';

const loadSpec = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch (e1) {
    try {
      return jsYaml.load(value);
    } catch (e2) {
      return undefined;
    }
  }
};

const RE_URL = /^http(s)?:\/\/[a-z\d.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/i;
// const v2Validator = new OpenAPISchemaValidator({ version: 2 });
// const v3Validator = new OpenAPISchemaValidator({ version: 3 });

export const dynamicContentPickerPickerValidation = (value: string, validation: FieldValidation) => {
  if (RE_URL.test(value)) {
    return;
  }

  // todo: implement correct validation
  const spec = loadSpec(value);
  switch (true) {
    case _.isString(spec.openapi) && /^3\.\d{1,2}\.\d{1,2}$/.test(spec.openapi): {
      // const { errors } = v3Validator.validate(spec as OpenAPIV3.Document);
      // if (_.size(errors)) {
      //   for (const error of errors) {
      //     validation.addError(`Specification validation error - ${error.message}`);
      //   }
      // }
      break;
    }
    case _.isString(spec.swagger) && /^2\.\d$/.test(spec.swagger): {
      // const { errors } = v2Validator.validate(spec as OpenAPIV2.Document);
      // if (_.size(errors)) {
      //   for (const error of errors) {
      //     validation.addError(`Specification validation error - ${error.message}`);
      //   }
      // }
      break;
    }
    default: {
      validation.addError('Unknown specification format');
    }
  }
};
